<template>
<div>

    <div class="card">
        <div class="card-header bg-primary">
            <h6 class="my-0">Configuraciones</h6>
        </div>
        <div class="card-body pt-0 pb-5">
            <form autocomplete="off">
                <div class="form-body">
                    <el-tabs type="border-card">
                        <el-tab-pane label="Servicios">
                            <div class="row">
                                <div class="col-md-4">
                                    <label class="control-label">Envío de comprobantes automático</label>
                                    <div class="form-group" :class="{'has-danger': errors.send_auto}">
                                        <el-switch v-model="form.send_auto" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                        <small class="text-danger" v-if="errors.send_auto" v-text="errors.send_auto[0]"></small>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label class="control-label">Envío de Guia de Remisión automático</label>
                                    <div class="form-group" :class="{'has-danger': errors.auto_send_dispatchs_to_sunat}">
                                        <el-switch v-model="form.auto_send_dispatchs_to_sunat" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                        <small class="text-danger" v-if="errors.auto_send_dispatchs_to_sunat" v-text="errors.send_auto[0]"></small>
                                    </div>
                                </div>
                                <div class="col-md-4" v-if="typeUser != 'integrator'">
                                    <label class="control-label">Crontab <small>Tareas Programadas</small></label>
                                    <div class="form-group" :class="{'has-danger': errors.cron}">
                                        <el-switch v-model="form.cron" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                        <small class="text-danger" v-if="errors.cron" v-text="errors.cron[0]"></small>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-4" v-if="typeUser != 'integrator'">
                                    <label class="control-label">Envío de comprobantes a servidor alterno de SUNAT</label>
                                    <div class="form-group" :class="{'has-danger': errors.sunat_alternate_server}">
                                        <el-switch v-model="form.sunat_alternate_server" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                        <small class="text-danger" v-if="errors.sunat_alternate_server" v-text="errors.sunat_alternate_server[0]"></small>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="Contable">
                            <div class="row">
                                <div class="col-md-4" v-if="typeUser != 'integrator'">
                                    <label class="control-label">Cantidad decimales POS</label>
                                    <div class="form-group" :class="{'has-danger': errors.decimal_quantity}">
                                        <el-input-number v-model="form.decimal_quantity" @change="submit" :min="2" :max="10"></el-input-number>
                                        <small class="text-danger" v-if="errors.decimal_quantity" v-text="errors.decimal_quantity[0]"></small>
                                    </div>
                                </div>

                                <div class="col-md-4" v-if="typeUser != 'integrator'">
                                    <label class="control-label">Impuesto bolsa plástica</label>
                                    <div class="form-group" :class="{'has-danger': errors.amount_plastic_bag_taxes}">
                                        <el-input-number v-model="form.amount_plastic_bag_taxes" @change="changeAmountPlasticBagTaxes" :precision="2" :step="0.1" :max="0.5" :min="0.1"></el-input-number>
                                        <small class="text-danger" v-if="errors.amount_plastic_bag_taxes" v-text="errors.amount_plastic_bag_taxes[0]"></small>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-4">
                                    <div class="form-group" :class="{'has-danger': errors.affectation_igv_type_id}">
                                        <label class="control-label">Tipo de afectación
                                            <el-tooltip class="item" effect="dark" content="Tipo de afectación predeterminada al registrar nuevo producto" placement="top-start">
                                                <i class="fa fa-info-circle"></i>
                                            </el-tooltip>
                                        </label>
                                        <el-select v-model="form.affectation_igv_type_id" @change="submit" filterable>
                                            <el-option v-for="option in affectation_igv_types" :key="option.id" :value="option.id" :label="option.description"></el-option>
                                        </el-select>
                                        <small class="text-danger" v-if="errors.affectation_igv_type_id" v-text="errors.affectation_igv_type_id[0]"></small>
                                    </div>
                                </div>

                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="Visual">
                            <div class="row">
                                <!-- <div class="col-md-6 mt-2">
                                            <label class="control-label">Opciones avanzadas en POS</label>
                                            <div class="form-group" :class="{'has-danger': errors.options_pos}">
                                                <el-switch v-model="form.options_pos" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                                <small class="text-danger" v-if="errors.options_pos" v-text="errors.options_pos[0]"></small>
                                            </div>
                                        </div> -->
                                <div class="col-md-6 mt-2">
                                    <label class="control-label">Menú lateral contraído</label>
                                    <div class="form-group" :class="{'has-danger': errors.compact_sidebar}">
                                        <el-switch v-model="form.compact_sidebar" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                        <small class="text-danger" v-if="errors.compact_sidebar" v-text="errors.compact_sidebar[0]"></small>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-2">
                                    <label class="control-label">Recrear Documento</label>
                                    <div class="form-group" :class="{'has-danger': errors.recreat_document}">
                                        <el-switch v-model="form.recreat_document" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                        <small class="text-danger" v-if="errors.recreat_document" v-text="errors.recreat_document[0]"></small>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-2">
                                    <label class="control-label">Eliminar Documento</label>
                                    <div class="form-group" :class="{'has-danger': errors.delete_document}">
                                        <el-switch v-model="form.delete_document" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                        <small class="text-danger" v-if="errors.delete_document" v-text="errors.delete_document[0]"></small>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-2">
                                    <label class="control-label">Actualizar Precio Venta</label>
                                    <div class="form-group" :class="{'has-danger': errors.refresh_price_sales}">
                                        <el-switch v-model="form.refresh_price_sales" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                        <small class="text-danger" v-if="errors.refresh_price_sales" v-text="errors.refresh_price_sales[0]"></small>
                                    </div>
                                </div>
                                <!-- <div class="col-md-6 mt-4">
                                            <label class="control-label">Opciones avanzadas en POS</label>
                                            <div class="form-group" :class="{'has-danger': errors.options_pos}">
                                                <el-switch v-model="form.options_pos" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                                <small class="text-danger" v-if="errors.options_pos" v-text="errors.options_pos[0]"></small>
                                            </div>
                                        </div> -->
                                <div class="col-md-6 mt-4">
                                    <label class="control-label">Editar nombre de productos</label>
                                    <div class="form-group" :class="{'has-danger': errors.edit_name_product}">
                                        <el-switch v-model="form.edit_name_product" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                        <small class="text-danger" v-if="errors.edit_name_product" v-text="errors.edit_name_product[0]"></small>
                                    </div>
                                </div>

                                <div class="col-md-6 mt-4">
                                    <label class="control-label">Restringir fecha de comprobante</label>
                                    <div class="form-group" :class="{'has-danger': errors.restrict_receipt_date}">
                                        <el-switch v-model="form.restrict_receipt_date" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                        <small class="text-danger" v-if="errors.restrict_receipt_date" v-text="errors.restrict_receipt_date[0]"></small>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-4">
                                    <a href="#" @click.prevent="showDialogTermsCondition = true" class="text-center font-weight-bold text-info">[+ Términos y condiciones - Cotización]</a>
                                </div>
                                <div class="col-md-6 mt-4">
                                    <label class="control-label">Mostrar cotización en finanzas</label>
                                    <div class="form-group" :class="{'has-danger': errors.cotizaction_finance}">
                                        <el-switch v-model="form.cotizaction_finance" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                        <small class="text-danger" v-if="errors.cotizaction_finance" v-text="errors.cotizaction_finance[0]"></small>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-4">
                                    <label class="control-label">Impuesto incluido en registro de productos</label>
                                    <div class="form-group" :class="{'has-danger': errors.include_igv}">
                                        <el-switch v-model="form.include_igv" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                        <small class="text-danger" v-if="errors.include_igv" v-text="errors.include_igv[0]"></small>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-4">
                                    <label class="control-label w-100">Productos de una ubicación</label>
                                    <div class="form-group" :class="{'has-danger': errors.product_only_location}">
                                        <el-switch v-model="form.product_only_location" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                        <small class="text-danger" v-if="errors.product_only_location" v-text="errors.product_only_location[0]"></small>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-4">
                                    <div class="form-group">
                                        <label class="control-label w-100">Mostar Restringir Stock</label>
                                        <el-switch v-model="form.show_restriction" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-4">
                                    <div class="form-group">
                                        <label class="control-label w-100">Mostar Impresión Contrato</label>
                                        <el-switch v-model="form.show_contract" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-4">
                                    <div class="form-group">
                                        <label class="control-label w-100">Mostar Credito Nota de Venta</label>
                                        <el-switch v-model="form.credits" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-4">
                                    <div class="form-group">
                                        <label class="control-label w-100">Impresion Directa de Comprobante</label>
                                        <el-switch v-model="form.print_direct" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-4">
                                    <div class="form-group">
                                        <label class="control-label w-100">Desactivar Precio Compra</label>
                                        <el-switch v-model="form.purchase_price_disable" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-4">
                                    <div class="form-group">
                                        <label class="control-label w-100">Api whatsapp</label>
                                        <el-switch v-model="form.api_whatsapp" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                    </div>
                                </div>
                                 <div class="col-md-6 mt-4">
                                    <div class="form-group">
                                        <label class="control-label w-100">Desactivar Modulos Facturación</label>
                                        <el-switch v-model="form.documents_active" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-4">
                                    <label class="control-label">Tasa de Interes % </label>
                                    <div class="form-group" :class="{'has-danger': errors.rates}">
                                        <template>
                                            <div class="el-input el-input-group el-input-group--append">
                                                <el-input-number class="w-100" v-model="form.rates" controls-position="right" :min="0" :precision="2" :max="100">
                                                </el-input-number>
                                                <div class="el-input-group__append">
                                                    <el-button @click="submit"> <i class="fa fa-check" aria-hidden="true"></i> </el-button>
                                                </div>
                                            </div>
                                        </template>
                                        <small class="text-danger" v-if="errors.rates" v-text="errors.rates[0]"></small>
                                    </div>
                                </div>
                                <div class="col-md-6  mt-4">
                                    <div class="form-group" :class="{'has-danger': errors.socket_channel}">
                                        <label class="control-label">Canal de Notificaciones</label>
                                        <div class="el-input el-input-group el-input-group--append">
                                            <el-input v-model="form.socket_channel"> 
                                            </el-input>
                                            <div class="el-input-group__append">
                                                <el-button @click="submit"> <i class="fa fa-check" aria-hidden="true"></i> </el-button>
                                            </div>
                                        </div>
                                        <small class="text-danger" v-if="errors.socket_channel" v-text="errors.socket_channel[0]"></small>
                                    </div>
                                </div>
                                <!-- <div class="col-md-6 mt-4">
                                            <label class="control-label">Restriccion de Stock</label>
                                            <div class="form-group" :class="{'has-danger': errors.show_restriction}">
                                                <el-switch v-model="form.show_restriction" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                                <small class="text-danger" v-if="errors.show_restriction" v-text="errors.show_restriction[0]"></small>
                                            </div>
                                        </div> -->
                            </div>
                        </el-tab-pane>

                    </el-tabs>

                </div>
            </form>
        </div>

        <terms-condition :showDialog.sync="showDialogTermsCondition" :form="form" :showClose="false"></terms-condition>
    </div>
</div>
</template>

<script>
import TermsCondition from '@views/quotations/partials/terms_condition.vue'

export default {
    props: ['typeUser'],
    components: {
        TermsCondition
    },

    data() {
        return {
            showDialogTermsCondition: false,
            loading_submit: false,
            resource: 'configurations',
            errors: {},
            form: {},
            affectation_igv_types: [],
            placeholder: '',

        }
    },
    async created() {
        await this.loadTables()
        await this.initForm();

        await this.$http.get(`/${this.resource}/record`).then(response => {
            if (response.data !== '') {
                this.form = response.data.data;
                console.log(this.form);
            }
            // console.log(this.placeholder)
        });
    },
    methods: {
        async loadTables() {

            await this.$http.get(`/${this.resource}/tables`).then(response => {
                this.affectation_igv_types = response.data.affectation_igv_types
            })

        },
        initForm() {
            this.errors = {};
            this.form = {
                send_auto: true,
                stock: true,
                cron: true,
                id: null,
                rates: 0,
                sunat_alternate_server: false,
                subtotal_account: null,
                decimal_quantity: null,
                amount_plastic_bag_taxes: 0.1,
                colums_grid_item: 4,
                affectation_igv_type_id: '10',
                terms_condition: null,
                compact_sidebar: null,
                delete_document: null,
                recreat_document: null,
                show_restriction: null,
                print_direct: null,
                socket_channel: null,
                refresh_price_sales: null,
                purchase_price_disable: null,
                auto_send_dispatchs_to_sunat:null,
            };
        },
        submit() {
            this.loading_submit = true;

            this.$http.post(`/${this.resource}`, this.form).then(response => {
                if (response.data.success) {
                    this.$message.success(response.data.message);
                } else {
                    this.$message.error(response.data.message);
                }
            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                } else {
                    console.log(error);
                }
            }).then(() => {
                this.loading_submit = false;
            });
        },
        changeAmountPlasticBagTaxes() {
            this.loading_submit = true;

            this.$http.post(`/${this.resource}/icbper`, this.form).then(response => {
                if (response.data.success) {
                    this.$message.success(response.data.message);
                } else {
                    this.$message.error(response.data.message);
                }
            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                } else {
                    console.log(error);
                }
            }).then(() => {
                this.loading_submit = false;
            });
        }
    }
}
</script>
