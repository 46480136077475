<template>
 <div>
     <div class="row">
         <div class="col-md-6">
              <div class="card">
        <div class="card-header bg-primary">
            <h6 class="my-0">Datos Sunat</h6>
        </div>
        <div class="card-body">
            <div class="title border-bottom pt-0">
                <h4>Certificado Digital</h4>
            </div>
            <div class="table-responsive pt-2" v-if="record">
                <table class="table">
                    <thead>
                    <tr>
                        <th>Archivo</th>
                        <th class="text-end">Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{{ record }}</td>
                        <td class="text-end">
                            <button type="button" class="btn waves-effect waves-light btn-xs btn-danger"
                                    @click.prevent="clickDelete">Eliminar</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="row pt-2" v-else>
                <div class="col-md-12">
                    <el-button  type="primary" @click="clickCreate">Subir Certificado .pfx</el-button>
                </div>
            </div>
            <div class="title border-bottom">
                <h4>Usuario Secundario SUNAT</h4>
            </div>
            <div class="row">
                <div class="col-md-6 py-2">
                    <div class="form-group">
                        <label class="control-label">Usuario</label>
                        <el-input v-model="soap_username"> </el-input>
                        <div class="sub-title text-muted"><small>RUC + Usuario. Ejemplo: 01234567890ELUSUARIO</small></div>
                    </div>
                </div>
                <div class="col-md-6 py-2">
                    <div class="form-group">
                        <label class="control-label">Contraseña</label>
                        <el-input v-model="soap_password"> </el-input>
                    </div>
                </div>
                <div class="col-md-12 py-2">
                    <div class="form-group">
                        <el-button type="primary" native-type="submit" @click.prevent="clickSaveSoapUser">Guardar</el-button>
                    </div>
                </div>
            </div>
        </div>
        <certificates-form :showDialog.sync="showDialog"
                           :recordId="recordId"></certificates-form>
    </div>
         </div>
          <div class="col-md-6">
              <div class="card">
        <div class="card-header bg-primary">
            <h6 class="my-0">Consulta RUC/DNI</h6>
        </div>
        <div class="card-body">
                        <div class="row">
                <div class="col-md-6 py-2">
                    <div class="form-group">
                        <label class="control-label">Webservices</label>
                        <el-input v-model="api_service_url"> </el-input>
                     </div>
                </div>
                <div class="col-md-6 py-2">
                    <div class="form-group">
                        <label class="control-label">Token</label>
                        <el-input v-model="api_service_token"> </el-input>
                    </div>
                </div>
                <div class="col-md-12 py-2">
                    <div class="form-group">
                        <el-button type="primary" native-type="submit" @click.prevent="clickSaveServices">Guardar</el-button>
                    </div>
                </div>
            </div>
        </div>
   </div>   
         </div>
     </div>
  
      
    </div>
</template>

<script>

    import CertificatesForm from './form.vue'
    import {deletable} from '../../../mixins/deletable'

    export default {
        mixins: [deletable],
        components: {CertificatesForm},
        data() {
            return {
                showDialog: false,
                resource: 'certificates',
                resource_webservice: 'configurations',
                recordId: null,
                record: {},
                soap_username:null,
                soap_password:null,
                api_service_url:null,
                api_service_token:null,
            }
        },
        created() {
            this.$eventHub.$on('reloadData', () => {
                this.getData()
            })
            this.getData()
        },
        methods: {
            clickSaveServices()
            {
                let api_service_url = this.api_service_url
                 let api_service_token = this.api_service_token
                  this.$http.post(`${this.resource_webservice}/services`, { api_service_url, api_service_token})
                    .then(response => {
                        if (response.data.success) {
                             this.$message.success(response.data.message)
                        } else {
                            this.$message.error(response.data.message)
                        }
                    })
                    .catch(error => {
                        this.$message.error("sucedio un error.");

                    })
                    .then(() => {

                    })
            },
            clickSaveSoapUser()
            {
                 let soap_username = this.soap_username
                 let soap_password = this.soap_password

                  this.$http.post(`${this.resource}/saveSoapUser`, { soap_username, soap_password})
                    .then(response => {
                        if (response.data.success) {

                             this.$message.success(response.data.message)

                        } else {

                            this.$message.error(response.data.message)
                        }
                    })
                    .catch(error => {

                        this.$message.error("Sucedio un error.");

                    })
                    .then(() => {

                    })

            },
            getData() {
                this.$http.get(`/${this.resource}/record`)
                    .then(response => {
                        this.record = response.data.certificate
                        this.soap_username = response.data.soap_username
                        this.soap_password = response.data.soap_password
                        //this.config_system_env = response.data.config_system_env
                    })
                     this.$http.get(`/${this.resource_webservice}/record`)
                    .then(response => {
                  
                         this.api_service_url = response.data.data.api_service_url
                          this.api_service_token = response.data.data.api_service_token
                        //this.config_system_env = response.data.config_system_env
                    })
            },
            clickCreate() {
                this.showDialog = true
            },
            clickDelete() {
                this.destroy(`/${this.resource}`).then(() =>
                    this.$eventHub.$emit('reloadData')
                )
            }
        }
    }
</script>
