import { render, staticRenderFns } from "./dashboard_stock.vue?vue&type=template&id=f4159d4a"
import script from "./dashboard_stock.vue?vue&type=script&lang=js"
export * from "./dashboard_stock.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports