var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-fluid p-l-0 p-r-0"},[_c('div',{staticClass:"page-header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_vm._m(0),_vm._v(" "),_c('ol',{staticClass:"breadcrumb"},[_vm._m(1),_vm._v(" "),_c('li',{staticClass:"breadcrumb-item active"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Los comprobantes se deben haber generado en entorno producción","placement":"top-start"}},[_c('i',{staticClass:"fa fa-info-circle"})])],1)])])])])]),_vm._v(" "),_c('div',{staticClass:"container-fluid p-l-0 p-r-0"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading_submit),expression:"loading_submit"}],staticClass:"card mb-0",attrs:{"element-loading-text":_vm.loading_text}},[_c('div',{staticClass:"card-body"},[_c('data-table',{attrs:{"resource":_vm.resource},scopedSlots:_vm._u([{key:"default",fn:function({ index, row }){return _c('tr',{},[_c('td',[_vm._v(_vm._s(index))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(row.soap_type_description))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(row.number)),_c('br'),_vm._v(" "),_c('small',{domProps:{"textContent":_vm._s(row.document_type_description)}}),_c('br'),_vm._v(" "),(row.affected_document)?_c('small',{domProps:{"textContent":_vm._s(row.affected_document)}}):_vm._e()]),_vm._v(" "),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(row.date_of_issue))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(row.customer_name)),_c('br'),_c('small',{domProps:{"textContent":_vm._s(row.customer_number)}})]),_vm._v(" "),_c('td',[_c('span',{staticClass:"badge bg-secondary text-white",class:{'bg-danger': (row.state_type_description == 'ANULADO'), 
                        'bg-warning': (row.state_type_description == 'POR ANULAR'), 
                        'bg-secondary': (row.state_type_description == 'REGISTRADO'), 
                        'bg-primary': (row.state_type_description == 'ENVIADO'), 
                        'bg-success': (row.state_type_description == 'ACEPTADO'), 
                        'bg-secondary': (row.state_type_description == 'OBSERVADO'), 
                        'bg-dark': (row.state_type_description == 'RECHAZADO'),
                        'bg-primary': (row.state_type_description == 'NO EXISTE')}},[_vm._v(_vm._s(row.state_type_description))])])])}}])},[_c('tr',{attrs:{"slot":"heading"},slot:"heading"},[_c('th',[_vm._v("#")]),_vm._v(" "),_c('th',[_vm._v("Entorno")]),_vm._v(" "),_c('th',[_vm._v("Comprobante")]),_vm._v(" "),_c('th',{staticClass:"text-center"},[_vm._v("F. Emisión")]),_vm._v(" "),_c('th',[_vm._v("Cliente")]),_vm._v(" "),_c('th',[_vm._v("Estado sistema")])]),_c('tr')])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h6',[_c('span',[_vm._v("Validador de documento")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"/dashboard"}},[_vm._v("Dashboard")])])
}]

export { render, staticRenderFns }