import { round } from "lodash"

function calculateRowItem(row_old, currency_type_id_new, exchange_rate_sale,includes=1,document='documents',redondear=true,percentage_igv=18) {


   let aplicar_igv= percentage_igv / 100
    let currency_type_id_old = row_old.item.currency_type_id
    let unit_price_igv=0

      let unit_price= parseFloat(row_old.item.unit_price)
    if(includes==false && row_old.affectation_igv_type_id=="20"){
        let calcular=(row_old.item.unit_price*row_old.quantity)
        unit_price_igv=round((calcular),2)
    }
    if(includes==false && row_old.affectation_igv_type_id=="10"){
        let calcular=(row_old.item.unit_price*row_old.quantity)
        unit_price_igv=round((calcular)*(this.percentage_igv/100),2)

    }
   // alert(unit_price_igv)
    // } else {
    //     unit_price = parseFloat(row_old.item.unit_price) * 1.18
    // }

    let warehouse_id = row_old.warehouse_id



    if (currency_type_id_old === 'PEN' && currency_type_id_old !== currency_type_id_new)
    {
        unit_price = (unit_price*exchange_rate_sale) / exchange_rate_sale;
    }

    if (currency_type_id_new === 'PEN' && currency_type_id_old !== currency_type_id_new)
    {
        unit_price = unit_price * exchange_rate_sale;
    }

    // unit_price = _.round(unit_price, 4);

    let row = {
        item_id: row_old.item.id,
        // item_description: row_old.item.description,
        item: row_old.item,
        currency_type_id: currency_type_id_new,
        quantity: row_old.quantity,
        unit_value: 0,
        affectation_igv_type_id: row_old.affectation_igv_type_id,
        affectation_igv_type: row_old.affectation_igv_type,
        total_base_igv: 0,
        percentage_igv: percentage_igv,
        total_igv: 0,
        system_isc_type_id: null,
        total_base_isc: 0,
        percentage_isc: 0,
        total_isc: 0,
        total_base_other_taxes: 0,
        percentage_other_taxes: 0,
        total_other_taxes: 0,
        total_plastic_bag_taxes: 0,
        total_taxes: 0,
        price_type_id: '01',
        unit_price: unit_price,
        unit_price_igv: unit_price_igv,
        input_unit_price_value: row_old.input_unit_price_value,
        total_value: 0,
        total_discount: 0,
        total_charge: 0,
        total: 0,
        attributes: row_old.attributes,
        charges: row_old.charges,
        discounts: row_old.discounts,
        warehouse_id: warehouse_id,
        name_product_pdf: row_old.name_product_pdf,
   //     unit_value:unit_value
    };
    let unit_value=0
    if(redondear==true){
        unit_value = row.unit_price

    }else{
         unit_value = unit_price
    }


    if (row.affectation_igv_type_id === '10') {
            unit_value = row.unit_price / (1 + percentage_igv / 100)
    }

    // row.unit_value = _.round(unit_value, 4)
   // alert(row.unit_price / (1 + percentage_igv / 100))
  //  alert(row.unit_price+(row.unit_price * (percentage_igv / 100)))
        row.unit_value = unit_value



    let total_value_partial = unit_value * row.quantity
     /* Discounts */
    let discount_base = 0
    let discount_no_base = 0
    row.discounts.forEach((discount, index) => {

        if(discount.is_amount){
            discount.base = _.round(total_value_partial, 2)
            //amount and percentage are equals in input
            discount.amount = _.round(discount.percentage, 2)
            discount.percentage =  _.round(100 * (parseFloat(discount.amount) / parseFloat(discount.base)),2)
            discount.factor = _.round(discount.percentage / 100, 2)
            if (discount.discount_type.base) {
                discount_base += discount.amount
            } else {
                discount_no_base += discount.amount
            }
        }else{
            discount.percentage = parseFloat(discount.percentage)
            discount.factor = discount.percentage / 100
            discount.base = _.round(total_value_partial, 2)
            discount.amount = _.round(discount.base * discount.factor, 2)
            if (discount.discount_type.base) {
                discount_base += discount.amount
            } else {
                discount_no_base += discount.amount
            }

        }

        row.discounts.splice(index, discount)
    })





    /* Charges */
    let charge_base = 0
    let charge_no_base = 0
    row.charges.forEach((charge, index) => {
        charge.percentage = parseFloat(charge.percentage)
        charge.factor = charge.percentage / 100
        charge.base = _.round(total_value_partial, 2)
        charge.amount = _.round(charge.base * charge.factor, 2)
        if (charge.charge_type.base) {
            charge_base += charge.amount
        } else {
            charge_no_base += charge.amount
        }
        row.charges.splice(index, charge)
    })


    let total_isc = 0
    let total_other_taxes = 0

    let total_discount = discount_base + discount_no_base
    let total_charge = charge_base + charge_no_base
    let total_value = total_value_partial - total_discount + total_charge
   // alert(total_value_partial)
   // alert(total_discount)
   // alert(total_charge)

    let total_base_igv = total_value_partial - discount_base + total_isc

    let total_igv = 0

    if (row.affectation_igv_type_id === '10') {
        total_igv = total_base_igv * percentage_igv / 100

    }
    if (row.affectation_igv_type_id === '20') { //Exonerated
        total_igv = 0
    }
    if (row.affectation_igv_type_id === '30') { //Unaffected
        total_igv = 0
    }

    let total_taxes = total_igv + total_isc + total_other_taxes
    let total = total_value + total_taxes

    row.total_charge = _.round(total_charge, 2)
    row.total_discount = _.round(total_discount, 2)
    row.total_value = _.round(total_value, 2)
    row.total_base_igv = _.round(total_base_igv, 2)
    row.total_igv =  _.round(total_igv, 2)
    row.total_taxes = _.round(total_taxes, 2)
    if(document=='sale-notes'){
        let monto_final=Math.round(parseFloat(total)  * 10) / 10
        row.total =monto_final
    }else{
        row.total=_.round(total,2)
    }

    if (row.affectation_igv_type.free) {
        row.price_type_id = '02'
        row.unit_value = 0
        // row.total_value = 0
        row.total = 0
    }
    //impuesto bolsa
    if(row_old.has_plastic_bag_taxes){
        row.total_plastic_bag_taxes = _.round(row.quantity * row.item.amount_plastic_bag_taxes, 1)
    }

    return row
}

export {calculateRowItem}
