export default {
    deb: {},
    userType: '',
    showColorDialog: false,
    resource: '',
    hasGlobalIgv: false,
    loading_submit: false,
    color: {},
    person: {},
    parentPerson: {},
    exchange_rate_sale: 1,
    exchange_rate: 1,
    currency_types: [],
    table_data: [],
    all_series: [],
    series: [],
    affectation_igv_types: [],
    payment_destinations: [],
    unit_types: [],
    item_search_extra_parameters: {},
    mold: {},
    config: {
        id: 0,
        show_totals_on_cpe_list:false,
        pos_history:true,
        production_app:false,
        pos_cost_price:true,
        company: {},
        establishment: {
            id: null,
            address: null,
            email: null,
            telephone: null,
            district: {description: null},
            province: {description: null},
            department: {description: null},
            country: {description: null},

        },
        send_auto: false,
        formats: 0,
        warehouse_id: 0,
        item_name_pdf_description: false,
        stock: false,
        show_service_on_pos: false,
        dispatches_address_text: false,
        cron: false,
        sunat_alternate_server: false,
        compact_sidebar: false,
        subtotal_account: 0,
        decimal_quantity: 0,
        amount_plastic_bag_taxes: 0,
        colums_grid_item: 0,
        options_pos: false,
        edit_name_product: false,
        restrict_receipt_date: false,
        affectation_igv_type_id: 0,
        visual: 0,
        enable_whatsapp: false,
        terms_condition: 0,
        terms_condition_sale: 0,
        cotizaction_finance: false,
        include_igv: false,
        product_only_location: false,
        legend_footer: false,
        default_document_type_03: false,
        header_image: 0,
        destination_sale: false,
        quotation_allow_seller_generate_sale: 0,
        allow_edit_unit_price_to_seller: 0,
        finances: 0,
        ticket_58: false,
        seller_can_create_product: false,
        seller_can_view_balance: false,
        seller_can_generate_sale_opportunities: false,
        update_document_on_dispaches: false,
        is_pharmacy: false,
        auto_send_dispatchs_to_sunat: false,
        send_data_to_other_server: false,
        item_per_page: 25,
        active_warehouse_prices: false,
        active_allowance_charge: false,
        search_item_by_series: false,
        change_free_affectation_igv: false,
        select_available_price_list: false,
        show_extra_info_to_item: false,
        enabled_global_igv_to_purchase: false,
        percentage_allowance_charge: 0,
        group_items_generate_document: 0,
        currency_type_id: 0,
        currency_types: [{}],
        affectation_igv_types_exonerated_unaffected: [{}],
        typeUser: '',
        show_items_only_user_stablishment: false,
        show_pdf_name: false,
        user: {
            serie: null,
            document_id: null,
            type: '',
        }

    },
    form_pos: {},
    currencys: [],
    sellers: [],
    payment_method_types: [],
    document_types_guide: [],
    warehouses: {},
    all_items: [],
    items: [],
    item: {},
    office: {},
    file: {},
    files: [],
    processes: [],
    records: [],
    colors: [],
    molds: [],
    periods: [],
    form_data: {},
    CatItemMoldProperty: [],
    CatItemUnitBusiness: [],
    CatItemStatus: [],
    CatItemPackageMeasurement: [],
    CatItemProductFamily: [],
    CatItemSize: [],
    CatItemUnitsPerPackage: [],
    CatItemMoldCavity: [],
    extra_colors: [],
    extra_CatItemSize: [],
    extra_CatItemUnitsPerPackage: [],
    extra_CatItemMoldProperty: [],
    extra_CatItemUnitBusiness: [],
    extra_CatItemStatus: [],
    extra_CatItemPackageMeasurement: [],
    extra_CatItemMoldCavity: [],
    extra_CatItemProductFamily: [],
    pagination: {
        current_page: 1,
        total: 0,
        per_page: 25,
    },
    actions: [],
    offices: [],
    documentTypes: [],
    statusDocumentary: [],
    all_customers: [],
    plans: [],
    customer: {},
    parent_customer: {},
    children_customer: {},
    customers: [],
    customer_addresses: [],
    workers: [],
    countries: [],
    all_departments: [],
    all_provinces: [],
    all_districts: [],
    identity_document_types: [],
    locations: [],
    person_types: [],
    company: {
        logo: '',
        name: '',
    },
    establishment: {
        address: '-',
        district: {description: ''},
        province: {description: ''},
        department: {description: ''},
        country: {description: ''},
        telephone: '-',
        email: null,
    },
}
