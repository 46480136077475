<template>
    <div>
    <div class="container-fluid p-l-0 p-r-0">
            <div class="page-header">
                <div class="row">
                <div class="col-sm-6">
                    <h6><span>Pedidos</span></h6>
                    <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                        <li class="breadcrumb-item active"><span class="text-muted">Pedidos</span></li>
                    </ol>
                </div>
                    <div class="col-sm-6">
                    <div class="bookmark">
                    <ul>
                        <li  v-if="typeUser != 'integrator'">
                            <el-tooltip class="item" effect="dark" content="Nuevo" placement="bottom-end">
                                <a :href="`/${resource}/create`" data-container="body" data-bs-toggle="popover" data-placement="top" data-original-title="Tables">
                                    <i data-feather="plus-circle"></i>
                                </a>
                            </el-tooltip>
                        </li>
                    </ul>
                </div>
                </div>
                </div>
            </div>
    </div>
    <div class="container-fluid p-l-0 p-r-0">
        <div class="card mb-0">
            <div class="data-table-visible-columns">
                <el-dropdown :hide-on-click="false">
                    <el-button type="primary">
                        Mostrar/Ocultar columnas<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="(column, index) in columns" :key="index">
                            <el-checkbox v-model="column.visible">{{ column.title }}</el-checkbox>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="card-body">
                <data-table :resource="resource">
                    <tr slot="heading">
                        <th>#</th>
                        <th class="text-center">Fecha Emisión</th>
                        <th class="text-center" v-if="columns.delivery_date.visible">Fecha Entrega</th>
                        <th>Vendedor</th>
                        <th>Cliente</th>
                        <th>Estado</th>
                        <th>Pedido</th>
                        <th>Comprobantes</th>
                        <th v-if="columns.sale_notes.visible">Nota de venta</th>
                        <!-- <th>Estado</th> -->
                        <th class="text-center">Moneda</th>
                        <th class="text-end" v-if="columns.total_exportation.visible">T.Exportación</th>
                        <th class="text-end" v-if="columns.total_unaffected.visible">T.Inafecta</th>
                        <th class="text-end" v-if="columns.total_exonerated.visible">T.Exonerado</th>
                        <th class="text-end" v-if="columns.total_taxed.visible">T.Gravado</th>
                        <th class="text-end" v-if="columns.total_igv.visible">T.Igv</th>
                        <th class="text-end">Total</th>
                        <th class="text-center">PDF</th>
                        <th class="text-end">Acciones</th>
                    <tr>
                    <tr slot-scope="{ index, row }" :class="{ anulate_color : row.state_type_id == '11' }">
                        <td>{{ index }}</td>
                        <td class="text-center">{{ row.date_of_issue }}</td>
                        <td class="text-center" v-if="columns.delivery_date.visible">{{ row.delivery_date }}</td>
                        <td>{{ row.user_name }}</td>
                        <td>{{ row.customer_name }}<br/><small v-text="row.customer_number"></small></td>
                        <td>{{row.state_type_description}}</td>
                        <td>{{ row.identifier }}
                        </td>
                        <td>
                            <template v-for="(document,i) in row.documents">
                                <label :key="i" v-text="document.number_full" class="d-block"></label>
                            </template>
                        </td>
                        <td v-if="columns.sale_notes.visible">
                            <template v-for="(sale_note,i) in row.sale_notes">
                                <label :key="i" v-text="sale_note.identifier" class="d-block"></label>
                            </template>
                        </td>
                        <!-- <td>{{ row.state_type_description }}</td> -->
                        <td class="text-center">{{ row.currency_type_id }}</td>
                        <td class="text-end"  v-if="columns.total_exportation.visible" >{{ row.total_exportation }}</td>
                        <td class="text-end" v-if="columns.total_unaffected.visible">{{ row.total_unaffected }}</td>
                        <td class="text-end" v-if="columns.total_exonerated.visible">{{ row.total_exonerated }}</td>
                        <td class="text-end" v-if="columns.total_taxed.visible">{{ row.total_taxed }}</td>
                        <td class="text-end" v-if="columns.total_igv.visible">{{ row.total_igv }}</td>
                        <td class="text-end">{{ row.total }}</td>
                        <td class="text-end">

                            <button type="button" class="btn waves-effect waves-light btn-xs btn-info"
                                    @click.prevent="clickOptionsPdf(row.id)">PDF</button>
                        </td>

                        <td class="text-end">
                            <button v-if="row.state_type_id != '11' && row.btn_generate && typeUser == 'admin' &&  soapCompany != '03'"  type="button" class="btn waves-effect waves-light btn-xs btn-info"
                                    @click.prevent="clickOptions(row.id)" >Generar Nuevo comprobante</button>

                            <a v-if="row.documents.length == 0 && row.state_type_id != '11'" :href="`/${resource}/edit/${row.id}`" type="button" class="btn waves-effect waves-light btn-xs btn-info">Editar</a>
                            <button v-if="row.documents.length == 0 && row.state_type_id != '11'" type="button" class="btn waves-effect waves-light btn-xs btn-danger" @click.prevent="clickAnulate(row.id)">Anular</button>
                            <button @click="duplicate(row.id)"  type="button" class="btn waves-effect waves-light btn-xs btn-info">Duplicar</button>
                            <a :href="`/dispatches/create/${row.id}/on`" class="btn waves-effect waves-light btn-xs btn-warning m-1__2">Guía</a>


                        </td>

                    </tr>
                </data-table>
            </div>


            <QuotationOptions :showDialog.sync="showDialogOptions"
                              :configuration.sync="configuration"
                              :recordId.sync="recordId"
                              :showGenerate="true"
                              :showClose="true">
            </QuotationOptions>

            <QuotationOptionsPdf :showDialog.sync="showDialogOptionsPdf"
                              :recordId="recordId"
                              :showClose="true">
            </QuotationOptionsPdf>
        </div>
    </div>
    </div>
</template>
<style scoped>
    .anulate_color{
        color:red;
    }
</style>
<script>

    import QuotationOptions from './partials/options.vue'
    import QuotationOptionsPdf from './partials/options_pdf.vue'
    import DataTable from '@components/DataTable.vue'
    import {deletable} from '@mixins/deletable'

    export default {
        props:['typeUser', 'soapCompany','configuration'],
        mixins: [deletable],
        components: {DataTable,QuotationOptions, QuotationOptionsPdf},
        data() {
            return {
                resource: 'order-notes',
                recordId: null,
                showDialogOptions: false,
                showDialogOptionsPdf: false,
                columns: {
                    total_exportation: {
                        title: 'T.Exportación',
                        visible: false
                    },
                    total_unaffected: {
                        title: 'T.Inafecto',
                        visible: false
                    },
                    total_exonerated: {
                        title: 'T.Exonerado',
                        visible: false
                    },
                    total_taxed: {
                        title: 'T.Gravado',
                        visible: true
                    },
                    total_igv: {
                        title: 'T.IGV',
                        visible: true
                    },
                    delivery_date: {
                        title: 'F.Entrega',
                        visible: true
                    },
                    sale_notes: {
                        title: 'Nota de venta',
                        visible: true
                    },
                }
            }
        },
        created() {
        },
        methods: {
            clickEdit(id)
            {
                this.recordId = id
                this.showDialogFormEdit = true
            },
            clickOptions(recordId = null) {
                this.recordId = recordId
                this.showDialogOptions = true
            },
            clickOptionsPdf(recordId = null) {
                this.recordId = recordId
                this.showDialogOptionsPdf = true
            },
            clickAnulate(id)
            {
                this.voided(`/${this.resource}/voided/${id}`).then(() =>
                    this.$eventHub.$emit('reloadData')
                )
            },
            duplicate(id)
            {
                this.$http.post(`${this.resource}/duplicate`, {id})
                .then(response => {
                    if (response.data.success) {
                        this.$message.success('Se guardaron los cambios correctamente.')
                        this.$eventHub.$emit('reloadData')
                    } else {
                        this.$message.error('No se guardaron los cambios')
                    }
                })
                .catch(error => {

                })
                this.$eventHub.$emit('reloadData')
            }
        }
    }
</script>
