<template>
    <div>
        <div class="row">

            <div class="col-md-12 col-lg-12 col-xl-12 ">

                <div class="row mt-2">

                        <div class="col-md-6">
                            <label class="control-label">Producto</label>
                            <el-select  v-model="form.item_id"
                                    filterable clearable>
                                <el-option v-for="option in items"  :key="option.id" :value="option.id" :label="option.full_description"></el-option>
                            </el-select>
                        </div>
                        <div class="col-md-3">
                            <label class="control-label">Fecha inicio</label>
                            <el-date-picker v-model="form.date_start" type="date"
                                            @change="changeDisabledDates"
                                            value-format="yyyy-MM-dd" format="dd/MM/yyyy" :clearable="true"></el-date-picker>
                        </div>
                        <div class="col-md-3">
                            <label class="control-label">Fecha término</label>
                            <el-date-picker v-model="form.date_end" type="date"
                                            :picker-options="pickerOptionsDates"
                                            value-format="yyyy-MM-dd" format="dd/MM/yyyy" :clearable="true"></el-date-picker>
                        </div>



                </div>
                <div class="row">
                    <div class="col-md-12">
                            <el-button class="submit" type="primary" @click.prevent="getRecordsByFilter" :loading="loading_submit" icon="el-icon-search" >Buscar</el-button>
                     </div>
                         <div class="col-md-6 m-t-10">
                               <template v-if="records.length>0">
                                <div class="form-group">
                                    <label class="control-label w-100">Exportar para usuario  </label>
                                    <el-button class="submit" type="danger"  icon="el-icon-tickets" @click.prevent="clickDownload('pdf')" >Exportar PDF</el-button>
                                    <el-button class="submit" type="success" @click.prevent="clickDownload('excel')"><i class="fa fa-file-excel" ></i>  Exportar Excel</el-button>
                                </div>

                            </template>

                         </div>
                         <div class="col-md-6 m-t-10">
                              <template v-if="records.length>0">
                                <label class="control-label">Exportar para sunat </label>
                                <div class="form-group">
                                     <el-button class="submit" type="success" @click.prevent="clickDownload2('excel')"><i class="fa fa-file-excel" ></i>  Exportar Excel</el-button>
                                     <el-button class="submit" type="default" @click.prevent="clickDownload2('txt')"><i class="fa fa-file-excel" ></i>  Exportar txt</el-button>
                                 </div>
                            </template>
                         </div>
                </div>
            </div>


            <div class="col-md-12">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                        <slot name="heading"></slot>
                        </thead>
                        <tbody>
                            <slot v-for="(row, index) in records" :row="row" :index="customIndex(index)"></slot>
                        </tbody>
                    </table>
                    <div>
                        <el-pagination
                                @current-change="getRecords"
                                layout="total, prev, pager, next"
                                :total="pagination.total"
                                :current-page.sync="pagination.current_page"
                                :page-size="pagination.per_page">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<style>
.font-custom{
    font-size:15px !important
}
</style>
<script>

    import moment from 'moment'
    import queryString from 'query-string'

    export default {
        props: {
            resource: String,
        },
        data () {
            return {
                loading_submit:false,
                columns: [],
                records: [],
                headers: headers_token,
                document_types: [],
                pagination: {},
                search: {},
                totals: {},
                establishment: null,
                items: [],
                form: {},
                pickerOptionsDates: {
                    disabledDate: (time) => {
                        time = moment(time).format('YYYY-MM-DD')
                        return this.form.date_start > time
                    }
                },
            }
        },
        computed: {
        },
        created() {
            this.initForm()
            this.$eventHub.$on('reloadData', () => {
                this.getRecords()
            })
        },
        async mounted () {

            await this.$http.get(`/${this.resource}/filter`)
                .then(response => {
                    this.items = response.data.items;
                });


            // await this.getRecords()

        },
        methods: {
            changeDisabledDates() {
                if (this.form.date_end < this.form.date_start) {
                    this.form.date_end = this.form.date_start
                }
                // this.loadAll();
            },
            clickDownload(type) {
                let query = queryString.stringify({
                    ...this.form
                });
                window.open(`/${this.resource}/${type}?${query}`, '_blank');
            },
             clickDownload2(type) {
                let query = queryString.stringify({
                    ...this.form
                });
                window.open(`/reports/inventorykardex/${type}?${query}`, '_blank');
            },
            initForm(){

                this.form = {
                    item_id:null,
                    date_start:null,
                    date_end:null,
                }

            },
            customIndex(index) {
                return (this.pagination.per_page * (this.pagination.current_page - 1)) + index + 1
            },
            async getRecordsByFilter(){

                // if(!this.form.item_id){
                //     return this.$message.error('El producto es obligatorio')
                // }

                this.loading_submit = await true
                await this.getRecords()
                this.loading_submit = await false

            },
            getRecords() {
                this.$eventHub.$emit('emitItemID', this.form.item_id)
                return this.$http.get(`/${this.resource}/records?${this.getQueryParameters()}`).then((response) => {
                    //********************************************
                    let data=response.data.data;
                    //let records_data =_.filter(data, { 'view': true });
                    this.records = data
                    //********************************************
                     this.pagination = response.data.meta
                    this.pagination.per_page = parseInt(response.data.meta.per_page)
                    this.loading_submit = false
                });


            },
            getQueryParameters() {
                return queryString.stringify({
                    page: this.pagination.current_page,
                    limit: this.limit,
                    ...this.form
                })
            },

        }
    }
</script>
