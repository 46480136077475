<template>
  <div>
    <div class="login-card">
      <div class="theme-form login-form needs-validation" novalidate="">
                 <div class="row">
                    <div class="col-md-12 d-flex justify-content-center pb-3 pt-2">
                       <template v-if="company.logo!=null || company.logo!=''">
                            <img class="text-center mx-auto"  width="200px" :src="`/storage/uploads/logos/${company.logo}`" alt="Logo" />
                       </template>
                       <template v-else>
                            <img class="text-center mx-auto"  width="200px" :src="`/storage/uploads/logo/700x300.jpg`" alt="Logo" />
                       </template>
                    </div>
                </div>

                <h5 class="text-center">BIENVENIDO A <b>{{company.name}}</b></h5>
                <h4 class="text-center">Acceso al Sistema</h4>
                  <div class="card-body">
                    <template v-if="bussiness_turns!=null">
                      <ul class="nav nav-tabs border-tab nav-primary" id="info-tab" role="tablist">
                        <li class="nav-item"><a class="nav-link active" id="info-home-tab" data-bs-toggle="tab" href="#info-home" role="tab" aria-controls="info-home" aria-selected="true"><i class="icofont icofont-man-in-glasses"></i>Admin</a></li>
                        <li class="nav-item"><a class="nav-link" id="profile-info-tab" data-bs-toggle="tab" href="#info-profile" role="tab" aria-controls="info-profile" aria-selected="false"><i class="icofont icofont-man-in-glasses"></i>Usuario</a></li>
                      </ul>
                    </template>
                    <div class="tab-content" id="info-tabContent">
                    <div class="tab-pane fade show active" id="info-home" role="tabpanel" aria-labelledby="info-home-tab">
                        
                          <div class="form-group mt-3">
                           <label>Correo Electronico</label>
                        <div class="input-group"><span class="input-group-text"><i class="icon-email"></i></span>
                          <input id="email" type="email" v-model="form.email"  placeholder="Test@gmail.com" class="form-control">
                        </div>
                        </div> 
                        <div class="form-group mt-3">
                          <label>Clave</label>
                          <div class="input-group"><span class="input-group-text"><i class="icon-lock"></i></span>
                            <input class="form-control" type="password" v-model="form.password" required="" >
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="checkbox">
                            <input v-model="form.remember" id="RememberMe" type="checkbox"  >
                            <label class="text-muted" for="checkbox1">Recordarme</label>
                          </div>
                        </div>
                        <div class="form-group">
                         <button type="button" class="btn btn-primary mt-1" @click="login()">Iniciar sesión</button>
                      </div>
                      <p>&copy; Copyright . Todos los derechos reservados</p>
                       
                    </div>
                      
                      <div class="tab-pane fade" id="info-profile" role="tabpanel" aria-labelledby="profile-info-tab">
                      
                          <div class="row align-items-center mt-2">
                          <div class="col-md-12">
                            <input
                              v-model="maskPin"
                              class="form-control"
                              type="text"
                              size="2"
                              name="listeanzahl"
                              value="10"
                              placeholder="Ingrese el Pin"
                              style="text-align: center"
                            />
                          </div>
                        </div>
                          
                               <div class="row justify-content-center mt-1">
                                  <div class="col p-1">
                                      <button class="button btn-primary" @click.prevent="getOption(1)">1</button>
                                  </div>
                                    <div class="col p-1">
                                      <button class="button btn-primary" @click.prevent="getOption(2)">2</button>
                                    </div>
                                    <div class="col p-1">
                                      <button class="button btn-primary" @click.prevent="getOption(3)">3</button>  
                                    </div> 
                              </div>
                                <div class="row justify-content-center mt-1">
                                    <div class="col p-1">
                                        <button class="button btn-primary" @click.prevent="getOption(4)">4</button>  
                                    </div> 
                                    <div class="col p-1">
                                        <button class="button btn-primary" @click.prevent="getOption(5)">5</button>  
                                    </div> 
                                    <div class="col p-1">
                                        <button class="button btn-primary" @click.prevent="getOption(6)">6</button>  
                                    </div> 
                                  
                                </div>
                                <div class="row justify-content-center mt-1">
                                    <div class="col p-1">
                                        <button class="button btn-primary" @click.prevent="getOption(7)">7</button>  
                                    </div> 
                                    <div class="col p-1">
                                        <button class="button btn-primary" @click.prevent="getOption(8)">8</button>  
                                    </div>                         
                                    <div class="col p-1">
                                        <button class="button btn-primary" @click.prevent="getOption(9)">9</button>  
                                    </div> 
                                  </div>
                                <div class="row mt-1 justify-content-center">
                                    <div class="col-4 p-1 text-center">
                                        <button class="button btn-primary" @click.prevent="getOption(0)">0</button>  
                                    </div> 
                                </div>
                                <div class="row mt-1 justify-content-center">
                                <div class="col-6">
                                    <button class="button btn btn-danger btn-sm" @click.prevent="getOption('delete')">
                                      Borrar
                                    </button>
                                </div>
                                <div class="col-6">
                                    <button class="button btn btn-success btn-sm" @click.prevent="enter">
                                      Ingresar
                                    </button>
                                </div>
                            </div>

                       
                         
                      </div>
                      </div>
                      
                      </div>
                     </div>
                  </div>
          
      </div>
</template>
<style scoped>
 .button {
    width: 100%;
    height: 40px;
    outline-style:initial;
    border: 1px solid #ccc;
    border-radius: 10px;
    cursor: pointer;
  } 
</style>
<script>
export default {
    props:['company','bussiness_turns'],
      data() {
        return {
            showDialog: false,
            showImportDialog: false,
            showImportListPriceDialog: false,
            showWarehousesDetail: false,
            resource: 'restaurant',
            recordId: null,
            warehousesDetail:[],
            config: {},
            maskPin: null,
            form:{}
        }
    },
    created() {
        this.initForm();
        this.getOption('delete');
    },
    methods: {
      getOption(option) {
        if (option == "delete") {
          this.pinHide = "";
          this.maskPin = null;
          return;
        }
        if (this.pinHide && this.pinHide.length == 4) {
          return;
        }
        this.pinHide += option.toString();

        this.maskPin = "*".repeat(this.pinHide.length);
      },
      async login(){
            this.$http.post('/auth', {
                        email: this.form.email,
                        password: this.form.password
                    })
                        .then(response => {
                            if (response.data.success) {
                                 let user = response.data.user;
                                this.$message.success(response.data.message);
                                 window.location.href = "dashboard"
                               //  this.$router.push({name: 'dashboard'})
                            } else {
                                this.error = response.data.message
                                this.$message.error(response.data.message);
                            }
                        })
                        .catch(function (error) {
                            console.error(error);
                        });
      },
      initForm(){
        this.form={
          email:null,
          password:null,
          remember:false,
        }
      },
      async enter() {
        const response = await this.$http.post(`${this.resource}/login`, { pin: this.pinHide });
        const {
          data: { success, kitchen },
        } = response;
              if (success) {
              this.$message.success("Sesión exitosa. Bienvenido/a .");
              if (response.data.kitchen==true) {
                window.location.href = "restaurant/worker/dashboard-kitchen";
              } else if (response.data.pos==true) {
                window.location.href = "restaurant/worker/dashboard-pos";
              } else if (response.data.waiter==true) {
                window.location.href = "restaurant/worker/dashboard";
              }
            } else {
              this.$message.error("El PIN no existe. Vuelva a intentarlo.");
              this.pinHide = "";
              this.maskPin = null;
            }

      },
      
    }
}
</script>
