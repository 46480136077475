window._ = require("lodash");
window.moment = require("moment");
window.Popper = require("popper.js");

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.$ = window.jQuery = require("jquery");
    require("bootstrap");
} catch (e) {}
/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
// window.axios = require("axios");

// window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

// /**
//  * Next we will register the CSRF Token as a common header with Axios so that
//  * all outgoing HTTP requests automatically have it attached. This is just
//  * a simple convenience so we don't have to attach every token manually.
//  */
// let token = document.head.querySelector('meta[name="csrf-token"]');

// if (token) {
//     window.axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;
//     window.headers_token = {
//         "X-CSRF-TOKEN": token.content
//     };
// } else {
//     console.error(
//         "CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token"
//     );
// }
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
let token = document.head.querySelector('meta[name="csrf-token"]');
if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
    window.headers_token = {
        'X-CSRF-TOKEN': token.content,
    }
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}
/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });
import Echo from "laravel-echo";

window.Pusher = require("pusher-js");
console.log("window.location.hostname",window.location.hostname)
// broadcaster: 'pusher',
// key: process.env.MIX_PUSHER_APP_KEY,
// cluster: process.env.MIX_PUSHER_APP_CLUSTER,
// forceTLS: true,
// encrypted: true,
// wsHost:"194.163.140.207",
// wsPort: 6001,
// disableStats: true,
// enabledTransports: ['ws', 'wss']
// try {
//     window.Echo = new Echo({
//         broadcaster: "pusher",
//         key: process.env.MIX_PUSHER_APP_KEY,
//         cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//         forceTLS: (process.env.MIX_APP_ENV=='production') ? true :false,
//         encrypted: true,
//         disableStats: (process.env.MIX_APP_ENV=='production') ? true :false,
//         wsHost: window.location.hostname,
//         wsPort: 6001

//     });
// } catch (e) {
//     console.log("Error al conectar websockets");
// }

require("./vendor/perfect-scrollbar.jquery.min");
require("./vendor/sidebarmenu");
require("./vendor/waves");
require("./vendor/custom");
