<template>
  <div>
    <div class="container-fluid p-l-0 p-r-0">
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <h6>Inventario Inicial</h6>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li class="breadcrumb-item active">
                <span class="text-muted">Inventario Inicial</span>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid p-l-0 p-r-0">
      <div class="row">
        <div class="col-sm-8">
          <div class="card mb-0">
            <div class="card-header bg-primary">
              <h4 class="my-0">Actualizar Inventario Inicial</h4>
            </div>
            <div class="card-body">
              <form autocomplete="off" @submit.prevent="submit">
                <div class="form-body">
                  <div class="row">
                    <div class="col-md-6 mt-2">
                        <label class="control-label">Borrar el Historial de Movimientos</label>
                        <div class="form-group" :class="{'has-danger': errors.clean_all}">
                            <el-switch v-model="form.clean_all" active-text="Si" inactive-text="No"></el-switch>
                            <small class="text-danger" v-if="errors.clean_all" v-text="errors.clean_all[0]"></small>
                        </div>
                    </div>
                    <div class="col-md-6 mt-2">
                        <label class="control-label">Actualizar el Saldo inicial</label>
                        <div class="form-group" :class="{'has-danger': errors.update_inventories}">
                            <el-switch v-model="form.update_inventories" active-text="Si" inactive-text="No"></el-switch>
                            <small class="text-danger" v-if="errors.update_inventories" v-text="errors.update_inventories[0]"></small>
                        </div>
                    </div>
                    <div
                      class="col-12 form-group"
                      :class="{ 'has-danger': errors.file }"
                    >
                      <el-upload
                        ref="upload"
                        :headers="headers"
                        action="/initinventory"
                        :show-file-list="true"
                        :auto-upload="false"
                        :multiple="false"
                        :on-error="errorUpload"
                        :before-upload="onBeforeUpload"
                        :limit="1"
                        :data="form"
                        :on-success="successUpload">
                        <el-button slot="trigger" type="primary">Seleccione un archivo (xlsx)</el-button>
                      </el-upload>
                      <small
                        class="form-control-feedback"
                        v-if="errors.file"
                        v-text="errors.file[0]"
                      ></small>
                    </div>
                    <div class="col-12 mt-4 mb-2">
                      <a
                        class="mr-auto"
                        href="/formats/inventory.xlsx"
                        target="_new">
                        <span class="mr-2">Descargar formato de ejemplo para importar</span>
                        <i class="fa fa-download"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="form-actions text-end pt-2 pb-2">
                  <el-button @click.prevent="close()">Cancelar</el-button>
                  <el-button
                    type="primary"
                    native-type="submit"
                    :loading="loading_submit"
                    >Procesar</el-button
                  >
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["typeUser"],
  data() {
    return {
      form: {},
      headers: headers_token,
      errors: {},
      loading_submit: false,
      resource: "inventory/storeupdate",
    };
  },
  async created() {
    await this.initForm();
  },
  methods: {
    initForm() {
      this.form = {
        update_inventories:true,
        clean_all:false,
      };
    },
    onBeforeUpload(file) {},
    errorUpload(response) {
        this.$message.success(response);
    },
    successUpload(response, file, fileList) {
      if (response.success) {
        this.$message.success(response.message);
        this.$eventHub.$emit("reloadData");
        this.$eventHub.$emit("reloadTables");
        this.$refs.upload.clearFiles();
        this.close();
      } else {
        this.$message({ message: response.message, type: "error" });
      }
    },
    async submit() {
      this.$confirm(
        "¿ Desea Actualizar el stock Inicial de los productos ?",
        "Aviso de Advertencia",
        {
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cerrar",
          type: "warning",
        }
      ).then(async () => {
        this.loading_submit = true;
        await this.$refs.upload.submit();
        this.loading_submit = false;

      });
    },
  },
};
</script>
