<template>
    <el-dialog :title="titleDialog" :visible="showDialog" @close="close" @open="create" append-to-body>
        <form autocomplete="off" @submit.prevent="submit">
            <div class="form-body"> 
                <div class="row">
                    <div class="col-lg-12 pb-2">
                        <div class="form-group" :class="{'has-danger': errors.customer_id}">
                            <label class="control-label font-weight-bold text-primary">
                                Cliente
                                <a href="#" @click.prevent="showDialogNewPerson = true">[+ Nuevo]</a>
                            </label>
                            <el-select v-model="form.customer_id" filterable remote class="border-left rounded-left border-info" popper-class="el-select-customers" 
                                dusk="customer_id"                                    
                                placeholder="Escriba el nombre o número de documento del cliente"
                                :remote-method="searchRemoteCustomers"
                                :loading="loading_search">

                                <el-option v-for="option in customers" :key="option.id" :value="option.id" :label="option.description"></el-option>

                            </el-select>
                            <small class="form-control-feedback" v-if="errors.customer_id" v-text="errors.customer_id[0]"></small>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-group" :class="{'has-danger': errors.description}">
                            <label class="control-label">Descripción </label>
                            <el-input type="textarea" v-model="form.description" > </el-input>
                            <small class="form-control-feedback" v-if="errors.description" v-text="errors.description[0]"></small>
                        </div>
                    </div> 

                    <div class="col-md-12">
                        <div class="form-group" :class="{'has-danger': errors.state}">
                            <label class="control-label">Estado </label>
                            <el-input type="textarea" v-model="form.state" > </el-input>
                            <small class="form-control-feedback" v-if="errors.state" v-text="errors.state[0]"></small>
                        </div>
                    </div> 
                    <div class="col-md-12">
                        <div class="form-group" :class="{'has-danger': errors.reason}">
                            <label class="control-label">Motivo de ingreso</label>
                            <el-input type="textarea" v-model="form.reason" > </el-input>
                            <small class="form-control-feedback" v-if="errors.reason" v-text="errors.reason[0]"></small>
                        </div>
                    </div> 
                    <div class="col-md-6">
                        <div class="form-group" :class="{'has-danger': errors.cellphone}">
                            <label class="control-label">Celular </label>
                            <el-input v-model="form.cellphone" > </el-input>
                            <small class="form-control-feedback" v-if="errors.cellphone" v-text="errors.cellphone[0]"></small>
                        </div>
                    </div> 
                    <div class="col-md-6">
                        <div class="form-group" :class="{'has-danger': errors.serial_number}">
                            <label class="control-label">Número de serie </label>
                            <el-input v-model="form.serial_number" > </el-input>
                            <small class="form-control-feedback" v-if="errors.serial_number" v-text="errors.serial_number[0]"></small>
                        </div>
                    </div> 
                    <div class="col-md-6">
                        <div class="form-group" :class="{'has-danger': errors.cost}">
                            <label class="control-label">Costo </label>
                            <el-input v-model="form.cost" > </el-input>
                            <small class="form-control-feedback" v-if="errors.cost" v-text="errors.cost[0]"></small>
                        </div>
                    </div> 
                    <div class="col-md-6">
                        <div class="form-group" :class="{'has-danger': errors.prepayment}">
                            <label class="control-label">Pago adelantado </label>
                            <el-input v-model="form.prepayment" > </el-input>
                            <small class="form-control-feedback" v-if="errors.prepayment" v-text="errors.prepayment[0]"></small>
                        </div>
                    </div> 
                    <div class="col-md-12" v-if="recordId">
                        <div class="form-group" :class="{'has-danger': errors.activities}">
                            <label class="control-label">Actividades realizadas (Equipo internado)</label>
                            <el-input type="textarea" v-model="form.activities" > </el-input>
                            <small class="form-control-feedback" v-if="errors.activities" v-text="errors.activities[0]"></small>
                        </div>
                    </div> 
                </div>
 
            </div>
            <div class="form-actions text-end pt-2 pb-2">
                <el-button @click.prevent="close()">Cancelar</el-button>
                <el-button type="primary" native-type="submit" :loading="loading_submit">Guardar</el-button>
            </div>
        </form>

        
        <person-form :showDialog.sync="showDialogNewPerson"
                       type="customers"
                       :external="true"
                       :document_type_id = form.document_type_id></person-form>
    </el-dialog>
</template>

<script>
    import PersonForm from '@views/persons/form.vue'

    export default {
        props: ['showDialog', 'recordId'],
        components: {PersonForm},
        data() {
            return {
                showDialogNewPerson: false,
                loading_submit: false,
                all_customers: [], 
                customers: [],
                titleDialog: null,
                resource: 'technical-services',
                loading_search:false,
                errors: {},
                form: {},
            }
        },
        async created() {
            await this.initForm()
            await this.$http.get(`/${this.resource}/tables`)
                .then(response => {
                    this.all_customers = response.data.customers
                    this.allCustomers()
                })

            this.$eventHub.$on('reloadDataPersons', (customer_id) => {
                this.reloadDataCustomers(customer_id)
            })
        }, 
        methods: {
            searchRemoteCustomers(input) {  
                
                if (input.length > 0) { 
                    this.loading_search = true
                    let parameters = `input=${input}`

                    this.$http.get(`/${this.resource}/search/customers?${parameters}`)
                            .then(response => { 
                                this.customers = response.data.customers
                                this.loading_search = false
                                if(this.customers.length == 0){this.allCustomers()}
                            })  
                } else { 
                    this.allCustomers()
                }

            },
            allCustomers() {
                this.customers = this.all_customers
            }, 
            reloadDataCustomers(customer_id) { 
                this.$http.get(`/${this.resource}/search/customer/${customer_id}`).then((response) => {
                    this.customers = response.data.customers
                    this.form.customer_id = customer_id
                })                  
            },
            initForm() {
                this.errors = {}
                this.form = {
                    id: null,
                    customer_id: null,
                    cellphone: null,
                    date_of_issue: moment().format('YYYY-MM-DD'),
                    time_of_issue: moment().format('HH:mm:ss'),
                    description: null,
                    state: null,
                    reason: null,
                    serial_number: null,
                    activities: null,
                    cost: 0,
                    prepayment: 0 
                }
            }, 
            create() { 

                this.titleDialog = (this.recordId)? 'Editar servicio técnico':'Nuevo servicio técnico'
                 
                if (this.recordId) {
                    this.$http.get(`/${this.resource}/record/${this.recordId}`)
                        .then(response => {
                            this.form = response.data.data
                            this.reloadDataCustomers(this.form.customer_id)
                        })
                }
            }, 
            submit() {
                
                if(parseFloat(this.form.prepayment) > parseFloat(this.form.cost)){
                    return this.$message.error('Pago adelantado no puede ser mayor al costo')
                }

                this.loading_submit = true
                this.$http.post(`/${this.resource}`, this.form)
                    .then(response => {
                        if (response.data.success) {
                            this.$message.success(response.data.message)
                            this.$eventHub.$emit('reloadData')
                            this.close()
                        } else {
                            this.$message.error(response.data.message)
                        }
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            this.errors = error.response.data
                        } else {
                            console.log(error)
                        }
                    })
                    .then(() => {
                        this.loading_submit = false
                    })
            }, 
            close() {
                this.$emit('update:showDialog', false)
                this.initForm()
            },
        }
    }
</script>
