<template>
    <div>
        <div class="container-fluid p-l-0 p-r-0">
            <div class="page-header">
              <div class="row">
                <div class="col-sm-6">
                  <h6><span>Boveda</span></h6>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                     <li class="breadcrumb-item active"><span class="text-muted">Boveda</span></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
         <div class="container-fluid p-l-0 p-r-0">
        <div class="card mb-0">
            <div class="card-header bg-primary">
                <h6 class="my-0">Boveda de Caja</h6>
            </div>
            <div class="card-body">
                <data-table :resource="resource">
                    <tr slot="heading">
                        <th>#</th>
                        <th class="text-left">Fecha</th>
                         <th class="text-left">Efectivo</th>
                        <th class="text-center">Destino</th>
                           <th class="text-center">Movimiento</th>
                   
                    <tr>
                    
                        <tr slot-scope="{ index, row }">
                            <td  class="text-left">{{ index + 1 }}</td>
                            <td class="text-left">{{row.date }}</td>
                             <td class="text-center">{{ row.amount }}</td>
                            <td class="text-center">{{ row.destination }}</td>
                            <td class="text-center">{{ row.destination }}</td>
                         
                        </tr>
                </data-table>
            </div>
            
  
        </div>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';
    import DataTable from '../../../components/DataTable.vue'
    import {deletable} from '../../../mixins/deletable'
    export default {
        mixins: [deletable],
        components: {DataTable},
        data() {
            return {
                title: null,
                showDialog: false,
                showImportDialog: false,
                showExportDialog: false,
                resource: 'vault',
                recordId: null,
                loading_submit: false,
                hoy:moment().format('DD-MM-YYYY'), 
                form_apertura:{},
                apertura:false,
                caja_cerrada:false
            }
        },
        created() {
            this.title = 'Caja'
 
        },
        methods: {
            clickCreate(recordId = 0) {
                if(recordId != 0 || recordId != null){
                        this.recordId = recordId
                        this.showDialog = true
                        this.loading_submit-=true
                }else{
                    this.$http.get(`/${this.resource}/statusarqueo`)
                        .then(res => {
                         if(res.data.status_caja==true){
                              this.$message.error("Cierre de Caja Pendiente")
                        }
                    })
                }
                     
                
            },
            clickImport() {
                this.showImportDialog = true
            },
            clickExport() {
                this.showExportDialog = true
            },
            clickDelete(id) {
                this.destroy(`/${this.resource}/${id}`).then(() =>
                    this.$eventHub.$emit('reloadData')
                )
            },
            clickApertura(){
                 this.form_apertura = {
                    id: null,
                    user_id: 1,
                    date_opening: moment().format('YYYY-MM-DD'),
                    time_opening: null,
                    date_closed: null,
                    time_closed: null,
                    beginning_balance: 0,
                    final_balance: 0,
                    income: 0, 
                    state: true,
                    reference_number: null
                }

                this.$confirm('¿Desea Aperturar la Caja con Monto Cero?', 'Aviso', {
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(() => {
                    this.$http.post(`/cash`,this.form_apertura)
                        .then(res => {
                            if(res.data.success) {
                                location.reload();
                                this.$message.success(res.data.message)
                                    this.$eventHub.$emit('reloadData')
                            }else{
                                this.$message.error(res.data.message)
                            }
                        })
                        .catch(error => {
                            if (error.response.status === 500) {
                                this.$message.error('Error al intentar habilitar');
                            } else {
                                console.log(error.response.data.message)
                            }
                        })
                }).catch(error => {
                    console.log(error)
                });
            },
            clickCerrar(id){
                this.$confirm('¿Desea cerrar la Caja?', 'Habilitar', {
                    confirmButtonText: 'Cerrar',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(() => {
                    this.$http.put(`/${this.resource}/deshabilitar/${id}`)
                        .then(res => {
                            if(res.data.success) {
                                location.reload();
                                this.$message.success(res.data.message)
                                    this.$eventHub.$emit('reloadData')
                            }else{
                                this.$message.error(res.data.message)
                            }
                        })
                        .catch(error => {
                            if (error.response.status === 500) {
                                this.$message.error('Error al intentar habilitar');
                            } else {
                                console.log(error.response.data.message)
                            }
                        })
                }).catch(error => {
                    console.log(error)
                });
            },
 
        }
    }
</script>