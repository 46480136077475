<template>
    <el-dialog
        :visible="is_payment"
        @open="date_of_issue()"
        width="50%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
    >
        <div class="card mb-0">
            <div class="card-header bg-primary text-white">
                <h6 class="my-0 text-white">Punto de Venta</h6>
            </div>
            <div class="card-body pt-1">
                <div class="row col-lg-12 m-0 p-0" v-loading="loading_submit">
                    <div class="row d-flex justify-content-center pt-2">
                        <div class="col-lg-6">
                            <p class="my-0"><small>Comprobante</small></p>
                            <div class="form-group">
                                <el-radio-group
                                    v-model="form.document_type_id"
                                    size="small"
                                    @change="
                                        filterSeries();
                                        date_of_issue();
                                    "
                                >
                                    <el-radio-button label="01"
                                        >FACTURA
                                    </el-radio-button>
                                    <el-radio-button label="03"
                                        >BOLETA
                                    </el-radio-button>
                                    <el-radio-button label="80"
                                        >N. VENTA
                                    </el-radio-button>
                                </el-radio-group>
                            </div>
                        </div>
                        <div class="col-lg-3 pl-0 pr-0">
                            <p class="my-0"><small>Serie</small></p>
                            <div class="form-group">
                                <el-select
                                    v-model="form.series_id"
                                    class="w-100"
                                >
                                    <el-option
                                        v-for="option in series"
                                        :key="option.id"
                                        :label="option.number"
                                        :value="option.id"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-6">
                            <div class="form-group">
                                <p class="my-0">
                                    <small>Fecha de emisión</small>
                                </p>
                                <el-date-picker
                                    v-model="form.date_of_issue"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    :clearable="false"
                                    format="dd-MM-yyyy"
                                    @change="changeDateOfIssue"
                                    :picker-options="datEmision"
                                >
                                </el-date-picker>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="card card-default mb-2">
                                <div class="card-body text-center p-2">
                                    <div class="row">
                                        <div
                                            class="col-lg-4"
                                            v-if="direct_printing == 1"
                                        >
                                            <p class="my-0">
                                                <small>Impresion Directa</small>
                                            </p>
                                            <div class="radio-tile-group">
                                                <div class="input-container">
                                                    <input
                                                        id="Printer"
                                                        v-model="printerOn"
                                                        class="radio-button"
                                                        type="radio"
                                                        name="radio"
                                                        value="1"
                                                        @click="printerOn = 1"
                                                    />
                                                    <div class="radio-tile">
                                                        <div
                                                            class="icon walk-icon"
                                                        >
                                                            <i
                                                                class="fas fa-print"
                                                            ></i>
                                                        </div>
                                                        <label
                                                            for="Printer"
                                                            class="radio-tile-label"
                                                            >Imprimir</label
                                                        >
                                                    </div>
                                                </div>

                                                <div class="input-container">
                                                    <input
                                                        id="printerOff"
                                                        v-model="printerOn"
                                                        class="radio-button"
                                                        type="radio"
                                                        name="radio"
                                                        value="2"
                                                        @click="printerOn = 2"
                                                    />
                                                    <div class="radio-tile">
                                                        <div
                                                            class="icon bike-icon"
                                                        >
                                                            <i
                                                                class="fas fa-print"
                                                            ></i>
                                                        </div>
                                                        <label
                                                            for="printerOff"
                                                            class="radio-tile-label"
                                                            >No Imprimir</label
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <p class="my-0">
                                                <small>Forma de Pago</small>
                                            </p>

                                            <div class="radio-tile-group2">
                                                <div class="input-container2">
                                                    <input
                                                        id="cash"
                                                        v-model="
                                                            method_payments
                                                        "
                                                        class="radio-button2"
                                                        type="radio"
                                                        name="method_payment"
                                                        value="01"
                                                        @change="
                                                            method_payment('01')
                                                        "
                                                    />
                                                    <div class="radio-tile2">
                                                        <div
                                                            class="icon walk-icon"
                                                        >
                                                            <i
                                                                class="far fa-money-bill-alt"
                                                            ></i>
                                                        </div>
                                                        <label
                                                            for="cash"
                                                            class="radio-tile-label2"
                                                            >Efectivo</label
                                                        >
                                                    </div>
                                                </div>
                                                <div class="input-container2">
                                                    <input
                                                        id="Tarjeta"
                                                        v-model="
                                                            method_payments
                                                        "
                                                        class="radio-button2"
                                                        type="radio"
                                                        name="method_payment"
                                                        value="02"
                                                        @change="
                                                            method_payment('02')
                                                        "
                                                    />
                                                    <div class="radio-tile2">
                                                        <div
                                                            class="icon bike-icon"
                                                        >
                                                            <i
                                                                class="fas fa-credit-card"
                                                            ></i>
                                                        </div>
                                                        <label
                                                            for="Tarjeta"
                                                            class="radio-tile-label2"
                                                            >Tarjeta</label
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <p class="my-0">
                                                <small>Monto a cobrar</small>
                                            </p>
                                            <h6 class="mb-2 mt-0">
                                                {{ currencyTypeActive.symbol }}
                                                {{ form.total }}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="card card-default mb-2">
                                <div class="card-body text-center p-1">
                                    <div class="row col-lg-12">
                                        <div class="col-lg-4">
                                            <div class="form-group">
                                                <label class="control-label"
                                                    >Ingrese monto</label
                                                >
                                                <el-input
                                                    ref="enter_amount"
                                                    v-model="form.enter_amount"
                                                    @input="enterAmount()"
                                                    @keyup.enter.native="
                                                        clickPayment()
                                                    "
                                                >
                                                    <template slot="prepend">{{
                                                        currencyTypeActive.symbol
                                                    }}</template>
                                                    <i
                                                        slot="prefix"
                                                        class="el-icon-edit-outline"
                                                    ></i
                                                ></el-input>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div
                                                class="form-group"
                                                :class="{
                                                    'has-danger':
                                                        form.difference < 0
                                                }"
                                            >
                                                <label
                                                    class="control-label"
                                                    v-text="
                                                        form.difference < 0
                                                            ? 'Faltante'
                                                            : 'Vuelto'
                                                    "
                                                ></label>
                                                <!-- <el-input v-model="difference" :disabled="true">
                                        <template slot="prepend">{{currencyTypeActive.symbol}}</template>
                                     </el-input> -->
                                                <h4
                                                    class="
                            control-label
                            font-weight-semibold
                            m-0
                            text-center
                            m-b-0
                          "
                                                >
                                                    {{
                                                        currencyTypeActive.symbol
                                                    }}
                                                    {{ form.difference }}
                                                </h4>
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-4">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="form-group">
                            <el-switch
                              @change="changeEnabledDiscount"
                              v-model="enabled_discount"
                              class="
                                control-label
                                font-weight-semibold
                                m-0
                                text-center
                                m-b-0
                              "
                              active-text="descuentos"
                            ></el-switch>
                            <el-input
                              v-model="discount_amount"
                              @input="inputDiscountAmount()"
                              :disabled="!enabled_discount"
                            >
                              <template slot="prepend">{{
                                currencyTypeActive.symbol
                              }}</template>
                              <i slot="prefix" class="el-icon-edit-outline"></i
                            ></el-input>
                          </div>
                        </div>
                      </div>
                    </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="method_payments == '01'">
                            <div class="card card-default mb-2">
                                <div class="card-body">
                                    <!-- <p class="text-center">Método de Pago</p> -->
                                    <div class="input-group mb-3">
                                        <div class="col-lg-12 m-bottom">
                                            <div class="row">
                                                <template
                                                    v-for="(pay,
                                                    index) in form.payments"
                                                >
                                                    <div
                                                        class="col-lg-1"
                                                        :key="pay.id"
                                                    >
                                                        <label
                                                            >{{
                                                                index + 1
                                                            }}.-</label
                                                        >
                                                    </div>
                                                    <div
                                                        class="col-lg-6"
                                                        :key="pay.id"
                                                    >
                                                        <label>{{
                                                            getDescriptionPaymentMethodType(
                                                                pay.payment_method_type_id
                                                            )
                                                        }}</label>
                                                    </div>
                                                    <div
                                                        class="col-lg-5"
                                                        :key="pay.id"
                                                    >
                                                        <label
                                                            ><strong
                                                                >{{
                                                                    currencyTypeActive.symbol
                                                                }}
                                                                {{
                                                                    pay.payment
                                                                }}</strong
                                                            >
                                                        </label>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-12 m-bottom">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <label class="control-label" >Método de Pago</label>

                                            <el-select v-model="form_payment.payment_method_type_id" @change="changePaymentMethodType">
                                                    <el-option v-for="option in payment_method_types" :key="option.id" :value="option.id" :label="option.description"></el-option>
                                            </el-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 m-bottom" v-if="has_card">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <label class="control-label" >Tarjeta
                                            <a class="text-info" @click.prevent="showDialogNewCardBrand = true" href="#">[+ Nueva]</a>
                                            </label>
                                            <el-select v-model="form_payment.card_brand_id">
                                                    <el-option v-for="option in cards_brand" :key="option.id" :value="option.id" :label="option.description"></el-option>
                                            </el-select>

                                        </div>

                                    </div>
                                </div>
                                <div class="col-lg-12 m-bottom" >
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <label class="control-label"  >Referencia</label>
                                            <el-input v-model="form_payment.reference" >
                                             </el-input>
                                        </div>
                                    </div>
                                </div>-->
                                        <div
                                            class="col-lg-12"
                                            v-if="
                                                form_payment.payment_method_type_id ==
                                                    '01'
                                            "
                                        >
                                            <div class="row">
                                                <div class="col-lg-3">
                                                    <button
                                                        class="btn btn-block btn-secondary"
                                                        @click="
                                                            setAmountCash(10)
                                                        "
                                                    >
                                                        {{
                                                            currencyTypeActive.symbol
                                                        }}10
                                                    </button>
                                                </div>
                                                <div class="col-lg-3">
                                                    <button
                                                        class="btn btn-block btn-secondary"
                                                        @click="
                                                            setAmountCash(20)
                                                        "
                                                    >
                                                        {{
                                                            currencyTypeActive.symbol
                                                        }}20
                                                    </button>
                                                </div>
                                                <div class="col-lg-3">
                                                    <button
                                                        class="btn btn-block btn-secondary"
                                                        @click="
                                                            setAmountCash(50)
                                                        "
                                                    >
                                                        {{
                                                            currencyTypeActive.symbol
                                                        }}50
                                                    </button>
                                                </div>
                                                <div class="col-lg-3">
                                                    <button
                                                        class="btn btn-block btn-secondary"
                                                        @click="
                                                            setAmountCash(100)
                                                        "
                                                    >
                                                        {{
                                                            currencyTypeActive.symbol
                                                        }}100
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-lg-4">
                                    <button
                                        class="btn btn-block btn-light border"
                                        @click="clickCancel"
                                    >
                                        <i class="fas fa-file-alt"></i> Nueva
                                        Venta
                                    </button>
                                </div>
                                <div class="col-lg-4">
                                    <button
                                        class="btn btn-block btn-primary"
                                        @click="clickPayment"
                                        :disabled="button_payment"
                                    >
                                        <i class="fas fa-money-bill-alt"></i>
                                        PAGAR
                                    </button>
                                </div>
                                <div class="col-lg-4">
                                    <button
                                        class="btn btn-block btn-dark"
                                        @click="back"
                                    >
                                        <i class="fa fa-reply"></i> Volver
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <multiple-payment-form
                        :showDialog.sync="showDialogMultiplePayment"
                        :payments="payments"
                        @add="addRow"
                    ></multiple-payment-form>

                    <!-- <sale-notes-options :showDialog.sync="showDialogSaleNote"
                          :recordId="saleNotesNewId"
                          :showClose="true"></sale-notes-options>  -->

                    <card-brands-form
                        :showDialog.sync="showDialogNewCardBrand"
                        :external="true"
                        :recordId="null"
                    ></card-brands-form>

                    <documents-options
                        :showDialog.sync="showDialogOptions"
                        :recordId="documentNewId"
                        :resource="resource_documents"
                    >
                    </documents-options>
                </div>
            </div>
        </div>
    </el-dialog>
</template>
<script>
// import * as shajs from 'sha.js';
import _ from "lodash";
import CardBrandsForm from "../../card_brands/form.vue";
// import SaleNotesOptions from '../../sale_notes/partials/options.vue'
//import OptionsForm from './options.vue'
import MultiplePaymentForm from "./multiple_payment.vue";
import DocumentsOptions from "./options_replaced.vue";
export default {
    components: { CardBrandsForm, MultiplePaymentForm, DocumentsOptions },

    props: [
        "form",
        "customer",
        "currencyTypeActive",
        "exchangeRateSale",
        "is_payment",
        "soapCompany",
        "percentage_igv",
        "direct_printing",
        "userId"
    ],
    data() {
        return {
            enabled_discount: false,
            discount_amount: 0,
            loading_submit: false,
            showDialogOptions: false,
            showDialogMultiplePayment: false,
            showDialogSaleNote: false,
            showDialogNewCardBrand: false,
            documentNewId: null,
            saleNotesNewId: null,
            resource_options: null,
            has_card: false,
            method_payments: "01",
            resource: "pos",
            resource_documents: "documents",
            resource_payments: "document_payments",
            amount: 0,
            printerOn: 1,
            button_payment: false,
            input_item: "",
            form_payment: {},
            series: [],
            all_series: [],
            cards_brand: [],
            cancel: false,
            form_cash_document: {},
            statusDocument: {},
            payment_method_types: [],
            last_date: null,
            payments: [],
            datEmision: {
                disabledDate(time) {
                    return time.getTime() > moment();
                }
            }
        };
    },
    async created() {
        await this.date_of_issue();
        await this.getTables();
        await this.initFormPayment();
        await this.setInitialAmount();

        // this.setAmountCash(this.form.total)
        this.$eventHub.$on("reloadDataCardBrands", card_brand_id => {
            this.reloadDataCardBrands(card_brand_id);
        });
        this.$eventHub.$on("localSPayments", payments => {
            this.payments = payments;
        });
        this.$eventHub.$on("closePayment", () => {
            this.back();
        });
        await this.getFormPosLocalStorage();
        qz.security.setCertificatePromise((resolve, reject) => {
            this.$http
                .get("/api/qz/crt/override", {
                    responseType: "text"
                })
                .then(response => {
                    console.log("override");
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.data);
                });
        });

        qz.security.setSignaturePromise(toSign => {
            return (resolve, reject) => {
                this.$http
                    .post("/api/qz/signing", { request: toSign })
                    .then(response => {
                        console.log("signing");
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error.data);
                    });
            };
        });

        this.setAmountCash(this.form.total);
        /*let row = _.filter(this.form_payment, { 'payment_method_type_id' : '01' })
            row.payment =  parseFloat(this.form.total)
            this.form.payments = this.payments
            let acum_payment = 0
            this.form.payments.forEach((item)=>{
                acum_payment += parseFloat(item.payment)
            })
            console.log("acum_payment",acum_payment)
            */
    },
    mounted() {
        this.teclasInit();
    },
    methods: {
        changeEnabledDiscount() {
            if (!this.enabled_discount) {
                this.discount_amount = 0;
                this.deleteDiscountGlobal();
                this.reCalculateTotal();
            }
        },
        teclasInit() {
            document.onkeydown = e => {
                const key = e.key;
                console.log("presiona", key);

                if (key == "F4") {
                    //Agregar cliente
                    this.clickPayment();
                }
                if (key == "F6") {
                    //Agregar Producto
                    //this.clickAddItemInvoice()
                    //return false;
                }
                if (key == "F7" && this.form.items.length > 0) {
                    //Guardar
                    // this.submit()
                }
                if (key == "F8") {
                    //Cancelar
                    // this.close()
                }
            };
        },
        async date_of_issue() {
            this.last_date = null;
            let form_date_of_issue = {
                document_type_id: this.form.document_type_id
            };
            const response_date = await this.$http.post(
                `/${this.resource}/date_of_issue`,
                form_date_of_issue
            );
            // this.$emit('update:last_date', response_date.data.date_last)
            this.last_date = response_date.data.date_last;
        },
        changeDateOfIssue() {
            this.form.date_of_due = this.form.date_of_issue;
            if (
                moment(this.form.date_of_issue) < moment().day(-1) &&
                this.configuration.restrict_receipt_date
            ) {
                this.$message.error(
                    "No puede seleccionar una fecha menor a 6 días."
                );
                this.dateValid = false;
            } else {
                this.dateValid = true;
            }
            this.form.date_of_due = this.form.date_of_issue;
            this.searchExchangeRateByDate(this.form.date_of_issue).then(
                response => {
                    this.form.exchange_rate_sale = response;
                }
            );
            this.form.exchange_rate_sale = 1;
        },
        NuevaVenta() {
            this.$parent.nueva_venta();
        },

        async setInitialAmount() {
            this.enter_amount = this.form.total;
            this.form.payments = this.payments;
            // this.$eventHub.$emit('eventSetFormPosLocalStorage', this.form)
            await this.$refs.enter_amount.$el
                .getElementsByTagName("input")[0]
                .focus();
            await this.$refs.enter_amount.$el
                .getElementsByTagName("input")[0]
                .select();
            //await this.$refs.enter_amount.$el.getElementsByTagName('input')[0].change()
            //console.log("this.$refs.enter_amount.$el.getElementsByTagName('input')[0]")
        },
        inputDiscountAmount() {
            if (this.enabled_discount) {
                if (
                    this.discount_amount &&
                    !isNaN(this.discount_amount) &&
                    parseFloat(this.discount_amount) > 0
                ) {
                    if (this.discount_amount >= this.form.total)
                        return this.$message.error(
                            "El monto de descuento debe ser menor al total de venta"
                        );
                    this.reCalculateTotal();
                } else {
                    this.deleteDiscountGlobal();
                    this.reCalculateTotal();
                }
            }
        },
        discountGlobal() {
            let global_discount = parseFloat(this.discount_amount);
            let base = parseFloat(this.form.total);
            let amount = parseFloat(global_discount);
            let factor = _.round(amount / base, 4);

            let discount = _.find(this.form.discounts, {
                discount_type_id: "03"
            });

            if (global_discount > 0 && !discount) {
                this.form.total_discount = _.round(amount, 2);

                this.form.total = _.round(this.form.total - amount, 2);

                this.form.total_value = _.round(this.form.total / (1+(this.percentage_igv/100)), 2);
                this.form.total_taxed = this.form.total_value;

                this.form.total_igv = _.round(this.form.total_value * (this.percentage_igv/100), 2);
                this.form.total_taxes = this.form.total_igv;

                this.form.discounts.push({
                    discount_type_id: "03",
                    description:
                        "Descuentos globales que no afectan la base imponible del IGV/IVAP",
                    factor: factor,
                    amount: amount,
                    base: base
                });
            } else {
                let index = this.form.discounts.indexOf(discount);

                if (index > -1) {
                    this.form.total_discount = _.round(amount, 2);

                    this.form.total = _.round(this.form.total - amount, 2);

                    this.form.total_value = _.round(this.form.total / (1+(this.percentage_igv/100)), 2);
                    this.form.total_taxed = this.form.total_value;

                    this.form.total_igv = _.round(
                        this.form.total_value * (this.percentage_igv/100),
                        2
                    );
                    this.form.total_taxes = this.form.total_igv;

                    this.form.discounts[index].base = base;
                    this.form.discounts[index].amount = amount;
                    this.form.discounts[index].factor = factor;
                }
            }

            // this.form.difference = this.enter_amount - this.form.total
            // console.log(this.form.discounts)
        },
        method_payment(payment_method_type_id) {
            this.form.payment_condition_id = payment_method_type_id;
            this.form.afectar_caja =
                payment_method_type_id == "01" ? true : false;
            this.form.payments[0].payment_method_type_id = payment_method_type_id;
            this.form_payment.payment_method_type_id = payment_method_type_id;
        },
        reCalculateTotal() {
            let total_discount = 0;
            let total_charge = 0;
            let total_exportation = 0;
            let total_taxed = 0;
            let total_exonerated = 0;
            let total_unaffected = 0;
            let total_free = 0;
            let total_igv = 0;
            let total_value = 0;
            let total = 0;
            let total_plastic_bag_taxes = 0;

            this.form.items.forEach(row => {
                total_discount += parseFloat(row.total_discount);
                total_charge += parseFloat(row.total_charge);

                if (row.affectation_igv_type_id === "10") {
                    total_taxed += parseFloat(row.total_value);
                }
                if (row.affectation_igv_type_id === "20") {
                    total_exonerated += parseFloat(row.total_value);
                }
                if (row.affectation_igv_type_id === "30") {
                    total_unaffected += parseFloat(row.total_value);
                }
                if (row.affectation_igv_type_id === "40") {
                    total_exportation += parseFloat(row.total_value);
                }
                if (
                    ["10", "20", "30", "40"].indexOf(
                        row.affectation_igv_type_id
                    ) < 0
                ) {
                    total_free += parseFloat(row.total_value);
                }
                if (
                    ["10", "20", "30", "40"].indexOf(
                        row.affectation_igv_type_id
                    ) > -1
                ) {
                    total_igv += parseFloat(row.total_igv);
                    total += parseFloat(row.total);
                }
                total_value += parseFloat(row.total_value);
                total_plastic_bag_taxes += parseFloat(
                    row.total_plastic_bag_taxes
                );
            });

            this.form.total_exportation = _.round(total_exportation, 2);
            this.form.total_taxed = _.round(total_taxed, 2);
            this.form.total_exonerated = _.round(total_exonerated, 2);
            this.form.total_unaffected = _.round(total_unaffected, 2);
            this.form.total_free = _.round(total_free, 2);
            this.form.total_igv = _.round(total_igv, 2);
            this.form.total_value = _.round(total_value, 2);
            this.form.total_taxes = _.round(total_igv, 2);
            this.form.total_plastic_bag_taxes = _.round(
                total_plastic_bag_taxes,
                2
            );
            // this.form.total = _.round(total, 2)
            this.form.total = _.round(
                total + this.form.total_plastic_bag_taxes,
                2
            );

            this.discountGlobal();
        },
        deleteDiscountGlobal() {
            let discount = _.find(this.form.discounts, {
                discount_type_id: "03"
            });
            let index = this.form.discounts.indexOf(discount);

            if (index > -1) {
                this.form.discounts.splice(index, 1);
                this.form.total_discount = 0;
            }
        },

        getFormPosLocalStorage() {
            let form_pos = localStorage.getItem("form_pos");
            form_pos = JSON.parse(form_pos);
            if (form_pos) {
                this.form.payments = form_pos.payments;
            }
        },
        clickAddPayment() {
            this.showDialogMultiplePayment = true;
        },
        reloadDataCardBrands(card_brand_id) {
            this.$http
                .get(`/${this.resource}/table/card_brands`)
                .then(response => {
                    this.cards_brand = response.data;
                    this.form_payment.card_brand_id = card_brand_id;
                    this.changePaymentMethodType();
                });
        },
        getDescriptionPaymentMethodType(id) {
            let payment_method_type = _.find(this.payment_method_types, {
                id: id
            });
            return payment_method_type ? payment_method_type.description : "";
        },
        changePaymentMethodType() {
            let payment_method_type = _.find(this.payment_method_types, {
                id: this.form_payment.payment_method_type_id
            });
            this.has_card = payment_method_type.has_card;
            this.form_payment.card_brand_id = payment_method_type.has_card
                ? this.form_payment.card_brand_id
                : null;
        },
        addRow(payments) {
            this.form.payments = payments;
            let acum_payment = 0;

            this.form.payments.forEach(item => {
                acum_payment += parseFloat(item.payment);
            });
            // this.amount = acum_payment
            this.setAmount(acum_payment);

            // console.log(this.form.payments)
        },
        setAmount(amount) {
            // this.amount = parseFloat(this.amount) + parseFloat(amount)
            this.form.enter_amount = amount;
            this.amount = parseFloat(amount);
            //   this.form.enter_amount =  parseFloat(amount) //+ parseFloat(amount)
            this.inputAmount();
        },
        setAmountCash(amount) {
            //   let row = _.filter(this.payments, { payment_method_type_id: "01" });
            //   row.payment = parseFloat(row.payment) + parseFloat(amount);
            //   this.form.payments = this.payments;
            //   let acum_payment = 0;
            //   this.form.payments.forEach((item) => {
            //     acum_payment += parseFloat(item.payment);
            //   });

            this.setAmount(amount);
        },
        async enterAmount() {
            let r_item = await _.last(this.payments, {
                payment_method_type_id: "01"
            });
            r_item.payment = await parseFloat(this.form.enter_amount);
            // console.log(r_item.payment)
            let ind = this.form.payments.length - 1;

                this.form.payments[ind].payment = parseFloat(
                    this.form.enter_amount
                );


            // this.setAmount(item.payment)
            let acum_payment = 0;
            await this.form.payments.forEach(item => {
                acum_payment += parseFloat(item.payment);
            });
            // console.log(this.form.payments)
            // this.amount = item.payment
            this.amount = acum_payment;
            // this.amount = this.enter_amount
            // console.log(this.amount)
            this.form.difference = this.amount - this.form.total;

            if (isNaN(this.form.difference)) {
                this.button_payment = true;
                this.form.difference = "-";
            } else if (this.form.difference >= 0) {
                this.button_payment = false;
                this.form.difference = this.amount - this.form.total;
            } else {
                this.button_payment = true;
            }
            this.form.difference = _.round(this.form.difference, 2);

            this.$eventHub.$emit("eventSetFormPosLocalStorage", this.form);
            //    await this.lStoPayment()
        },
        getLocalStoragePayment(key, re_default = null) {
            let ls_obj = localStorage.getItem(key);
            ls_obj = JSON.parse(ls_obj);

            if (ls_obj) {
                return ls_obj;
            }

            return re_default;
        },
        setLocalStoragePayment(key, obj) {
            localStorage.setItem(key, JSON.stringify(obj));
        },
        inputAmount() {
            this.enterAmount();
            this.form.difference = this.form.enter_amount - this.form.total;
            if (isNaN(this.form.difference)) {
                this.button_payment = true;
                this.form.difference = "-";
            } else if (this.form.difference >= 0) {
                this.button_payment = false;
                this.form.difference = this.amount - this.form.total;
            } else {
                this.button_payment = true;
            }
            //   this.form.difference = _.round(this.form.difference,2)
            // this.form_payment.payment = this.amount

            this.$eventHub.$emit("eventSetFormPosLocalStorage", this.form);
            //this.lStoPayment()
        },
        lStoPayment() {
            this.setLocalStoragePayment("enter_amount", this.form.enter_amount);
            this.setLocalStoragePayment(
                "amount",
                this.amount == 0 ? this.form.total : this.amount
            );
            console.log("amount", this.amount);
            this.setLocalStoragePayment(
                "difference",
                this.amount == 0 ? this.form.total - this.amount : 0
            );
            console.log("difference", this.form.difference);
        },
        initFormPayment() {
            this.form.difference = this.form.total - this.form.enter_amount;
            this.form_payment = {
                id: null,
                date_of_payment: moment().format("YYYY-MM-DD"),
                payment_method_type_id: "01",
                reference: null,
                card_brand_id: null,
                document_id: null,
                sale_note_id: null,
                payment: this.form.total
            };

            this.form_cash_document = {
                document_id: null,
                sale_note_id: null
            };
        },

        cleanLocalStoragePayment() {
            this.setLocalStoragePayment("form_pos", null);
            this.setLocalStoragePayment("amount", null);
            this.setLocalStoragePayment("enter_amount", null);
            this.setLocalStoragePayment("difference", null);
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async clickPayment() {
            // if(this.has_card && !this.form_payment.card_brand_id) return this.$message.error('Seleccione una tarjeta');
            if (!this.form.series_id) {
                return this.$message.warning(
                    "El establecimiento no tiene series disponibles para el comprobante"
                );
            }

            if (this.form.document_type_id === "80") {
                this.form.prefix = "NV";
                this.form.paid = 1;
                this.resource_documents = "sale-notes";
                this.resource_payments = "sale_note_payments";
                this.resource_options = this.resource_documents;
            } else {
                this.form.prefix = null;
                this.resource_documents = "documents";
                this.resource_payments = "document_payments";
                this.resource_options = this.resource_documents;
            }

            this.loading_submit = true;
            await this.$http
                .post(`/${this.resource_documents}`, this.form)
                .then(response => {
                    console.log(response);

                    if (response.data.success) {
                        if (this.form.document_type_id === "80") {
                            this.form_cash_document.sale_note_id =
                                response.data.data.id;
                        } else {
                            this.form_cash_document.document_id =
                                response.data.data.id;
                            this.statusDocument = response.data.data.response;
                        }
                        this.documentNewId = response.data.data.id;
                        this.saveCashDocument();
                        this.cleanLocalStoragePayment();
                        this.showDialogOptions = true;
                        this.$eventHub.$emit("saleSuccess");
                    } else {
                        this.$message.error(response.data.message);
                    }
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                    } else {
                        this.$message.error(error.response.data.message);
                    }
                })
                .then(() => {
                    this.loading_submit = false;
                });
            // const responses= this.$http.get(`/${this.resource_documents}/record/${this.documentNewId}`)
            // if (this.printerOn == 1) {
            //   await this.$http
            //     .get(`/${this.resource_documents}/record/${this.documentNewId}`)
            //     .then((responses) => {
            //       if (responses.data.data.direct_printing == "1") {
            //         let formatoPdf = "";
            //         if (responses.data.data.format_printer == "1") {
            //           formatoPdf = responses.data.data.print_a4;
            //         } else if (responses.data.data.format_printer == "2") {
            //           formatoPdf = responses.data.data.print_a5;
            //         } else if (responses.data.data.format_printer == "3") {
            //           formatoPdf = responses.data.data.print_ticket;
            //         } else if (responses.data.data.format_printer == "4") {
            //           formatoPdf = responses.data.data.print_ticket;
            //         }

            //         this.clickPrintPos(responses.data.data.printer, formatoPdf);
            //       }
            //     });
            // }
            // this.back();
        },

        async clickPrintPos(printerName, formatoPdf) {
            try {
                let config = qz.configs.create(printerName, { legacy: true });
                if (!qz.websocket.isActive()) {
                    await qz.websocket.connect(config);
                }
                let data = [
                    {
                        type: "pdf",
                        format: "file",
                        data: formatoPdf
                    }
                ];
                qz.print(config, data).catch(e => {
                    this.$message.error(e.message);
                });
                //       qz.print(config, data).catch((e) => {
                //         this.$message.error(e.message);
                //     });
                //   this.clickNewSale();
            } catch (e) {
                this.$message.error(e.message);
            }
        },
        saveCashDocument() {
            this.$http
                .post(`/cash/cash_document`, this.form_cash_document)
                .then(response => {
                    if (response.data.success) {
                        // console.log(response)
                    } else {
                        this.$message.error(response.data.message);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        savePaymentMethod() {
            this.$http
                .post(`/${this.resource_payments}`, this.form_payment)
                .then(response => {
                    if (response.data.success) {
                        // console.log(response)
                    } else {
                        this.$message.error(response.data.message);
                    }
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        this.records[index].errors = error.response.data;
                    } else {
                        console.log(error);
                    }
                });
        },
        getTables() {
            this.$http
                .get(`/${this.resource}/payment_tables`)
                .then(response => {
                    this.all_series = response.data.series;
                    this.payment_method_types =
                        response.data.payment_method_types;
                    this.cards_brand = response.data.cards_brand;
                    this.filterSeries();
                });
        },

        async clickCancel() {
            this.loading_submit = true;

            this.loading_submit = false;
            this.cleanLocalStoragePayment();
            this.$eventHub.$emit("cancelSale");
        },
        back() {
            this.$emit("update:is_payment", false);
        },
        async initLStoPayment() {
            this.amount = await this.getLocalStoragePayment(
                "amount",
                this.form.total
            );
            this.form.enter_amount = await this.getLocalStoragePayment(
                "enter_amount",
                this.form.total
            );
            this.form.difference = await this.getLocalStoragePayment(
                "difference",
                this.form.total - this.form.enter_amount
            );
        },
        filterSeries() {
            this.form.series_id = null;
            this.series = _.filter(this.all_series, {
                document_type_id: this.form.document_type_id
            });
            //    console.log("Series ",this.series)
            this.form.series_id =
                this.series.length > 0 ? this.series[0].id : null;

            if (!this.form.series_id) {
                return this.$message.warning(
                    "El establecimiento no tiene series disponibles para el comprobante"
                );
            }
        }
    }
};
</script>
