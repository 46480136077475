<template>
    <el-dialog :title="titleDialog" :visible="showDialog"   @close="close"   append-to-body top="7vh">
        <form autocomplete="off" @submit.prevent="submit">
            <div class="form-body">
                <div class="row">
                    <div class="col-md-12" v-if="warehouses">
                        <table class="table">
                            <thead>
                            <tr>
                                <th>Ubicación</th>
                                <th class="text-end">Stock</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="row in warehouses">
                                <th>{{ row.warehouse_description }}</th>
                                <th class="text-end">{{ row.stock }}</th>
                            </tr>
                            </tbody>
                        </table>

                        <h6>Lista de Precios Creados</h6>
                        <table class="table">
                          <thead>
                            <tr>
                              <th>Unidad</th>
                              <th>Description</th>
                              <th>Factor</th>
                              <th>Precio 1</th>
                              <th>Precio 2</th>
                              <th>Precio 3</th>
                              <th>P.Defecto</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(row,index) in unit_type" :key="index">
                              <th>{{ row.unit_type_id }}</th>
                              <th>{{ row.description }}</th>
                              <th>{{ row.quantity_unit }}</th>
                              <th>{{ row.price1 }}</th>
                              <th>{{ row.price2 }}</th>
                              <th>{{ row.price3 }}</th>
                              <th>{{ row.price_default }}</th>
                            </tr>
                          </tbody>
                        </table>

                    </div>
                </div>
            </div>
           <div class="form-actions text-end pt-2 pb-2">
                <el-button @click.prevent="close()">Cerrar</el-button>
            </div>
        </form>
    </el-dialog>
</template>

<script>


    export default {
        props:['showDialog', 'warehouses', 'unit_type'],
        data() {
            return {
                showImportDialog: false,
                resource: 'items',
                recordId: null,
                titleDialog: 'Stock de producto',

            }
        },
        created() {
            //console.log(this.typeUser)
        },
        methods: {
            close() {
                this.$emit('update:showDialog', false)
            },
        }
    }
</script>
