<template>
    <div>
 
    <div class="container-fluid p-l-0 p-r-0">
                <div class="page-header">
                <div class="row">
                    <div class="col-sm-6">
                    <h6><span>{{ title }}</span></h6>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                        <li class="breadcrumb-item active"><span class="text-muted">{{ title }}</span></li>
                    </ol>
                    </div>
                    <div class="col-sm-6">
                    <div class="bookmark">
                    <ul>
                        <li>
                            <el-tooltip class="item" effect="dark" content="Nuevo" placement="bottom-end">
                                <a href="javascript:void(0)" @click.prevent="clickCreate()" data-container="body" data-bs-toggle="popover" data-placement="top" data-original-title="Tables">
                                    <i data-feather="plus-circle"></i>
                                </a>
                            </el-tooltip>
                        </li>
                        </ul>
                    </div>
                    <!-- Bookmark Ends-->
                    </div>
                </div>
                </div>
    </div>
          <!-- Container-fluid starts-->
    <div class="container-fluid p-l-0 p-r-0">         
        <div class="card mb-0">
            <div class="card-header bg-primary">
                <h6 class="my-0">{{ title }}</h6>
            </div>
            <div class="card-body">
                <data-table :resource="resource">
                    <tr slot="heading">
                        <th>#</th>
                        <th>Cliente</th>
                        <th>Celular</th>
                        <th>Número</th>
                        <th>F. Emisión</th>
                        <th>N° Serie</th>
                        <th>Costo</th>
                        <th>Pago adelantado</th>
                        <th>Saldo</th>
                        <th class="text-center">Ver</th>
                        <th class="text-end">Acciones</th>
                    <tr>
                    <tr slot-scope="{ index, row }" >
                        <td>{{ index }}</td>
                        <td>{{ row.customer_name }}<br/><small v-text="row.customer_number"></small></td>
                        <td class="text-center">{{ row.cellphone }}</td>
                        <td class="text-center">{{ row.id }}</td>
                        <td class="text-center">{{ row.date_of_issue }}</td>
                        <td class="text-center">{{ row.serial_number }}</td>
                        <td class="text-center">{{ row.cost }}</td>
                        <td class="text-center">{{ row.prepayment }}</td>
                        <td class="text-center">{{ row.balance }}</td>

                        <td class="text-center">
                            <button type="button" class="btn waves-effect waves-light btn-xs btn-info" @click.prevent="clickPrint(row.id)" >PDF</button>
                        </td>

                        <td class="text-end">
                            
                            <button type="button" class="btn waves-effect waves-light btn-xs btn-info" @click.prevent="clickCreate(row.id)" >Editar</button>
                            <template v-if="typeUser === 'admin'">
                                <button type="button" class="btn waves-effect waves-light btn-xs btn-danger" @click.prevent="clickDelete(row.id)">Eliminar</button>
                            </template>

                        </td>
                    </tr>
                </data-table>
            </div>
        </div>
   </div>         
            <technical-services-form :showDialog.sync="showDialog"
                          :recordId="recordId"></technical-services-form>
 
        </div>
    </div>
</template>

<script>

    import TechnicalServicesForm from './form.vue'
    import DataTable from '@components/DataTable.vue'
    import {deletable} from '@mixins/deletable'

    export default {
        mixins: [deletable],
        props: ['typeUser'],
        components: {TechnicalServicesForm, DataTable},
        data() {
            return {
                title: null,
                showDialog: false,
                resource: 'technical-services',
                recordId: null,
            }
        },
        created() {
            this.title = 'Servicios de soporte técnico'
        },
        methods: {
            clickPrint(recordId){
                window.open(`/${this.resource}/print/${recordId}/a4`, '_blank');
            },
            clickCreate(recordId = null) {
                this.recordId = recordId
                this.showDialog = true
            }, 
            clickDelete(id) {
                this.destroy(`/${this.resource}/${id}`).then(() =>
                    this.$eventHub.$emit('reloadData')
                )
            }
        }
    }
</script>
