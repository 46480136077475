<template>
    <div>
        <div class="row">
            <div class="col-md-4 col-sm-6">
                <label
                    >Buscar Producto
                    <el-input
                        class="w-100"
                        ref="input_item"
                        size="medium"
                        v-model="input_item"
                        @change="searchItems"
                        @focus="clear_input()"
                        autofocus
                        ><i
                            slot="prefix"
                            class="el-input__icon el-icon-search"
                        ></i>
                        <template slot="prepend">Buscar</template>
                        <el-button
                            slot="append"
                            icon="el-icon-plus"
                            @click.prevent="showDialogNewItem = true"
                        ></el-button>
                    </el-input>
                </label>
            </div>
            <div class="col-md-4 col-sm-6">
                <template>
                    <label class="control-label w-100"
                        >Cliente
                        <div
                            class="el-input el-input-group el-input-group--append"
                        >
                            <el-select
                                ref="select_person"
                                v-model="form.customer_id"
                                filterable
                                size="medium"
                                placeholder="Cliente"
                                @change="changeCustomer"
                                @keyup.native="keyupCustomer"
                                @keyup.enter.native="
                                    keyupEnterCustomer();
                                    keyupCustomer();
                                "
                            >
                                <el-option
                                    v-for="option in all_customers"
                                    :key="option.id"
                                    :label="option.description"
                                    :value="option.id"
                                >
                                </el-option>
                            </el-select>
                            <div class="el-input-group__append">
                                <el-button @click="showDialogNewPerson = true">
                                    <i
                                        class="fas fa-users"
                                        aria-hidden="true"
                                    ></i>
                                    Cliente
                                </el-button>
                            </div>
                        </div>
                    </label>
                </template>
            </div>
            <div class="col-md-4 col-sm-6 pt-2 align-self-end">
                <template>
                    <div class="media">
                        <i class="fa fa-shopping-bag fa-3x p-r-10"></i>
                        <div class="media-body ml-2">
                            <blockquote class="blockquote border-0 p-0">
                                <p
                                    class="font-italic lead mb-0"
                                    style="font-size: 15px; font-weight: bold"
                                >
                                    PUNTO DE VENTA - {{ company }}
                                </p>
                                <footer
                                    style="
                      height: auto !important;
                      border: 0px !important;
                      padding: 0px !important;
                      margin: 0px !important;
                    "
                                    class="blockquote-footer"
                                >
                                    <cite title="Source Title">
                                        Productos Seleccionados
                                    </cite>
                                </footer>
                            </blockquote>
                        </div>
                    </div>
                </template>
            </div>
            <!-- <div class="col-md-4 col-sm-6">
             <div class="radio-tile-group">
                        <div class="input-container">
                          <input id="Descripcion"  v-model="buscar_por" class="radio-button" type="radio" name="radio" value="1" @click="back(1)" />
                          <div class="radio-tile">
                            <div class="icon walk-icon">
                            <i class="fa fa-list" aria-hidden="true"></i>
                            </div>
                            <label for="Descripcion" class="radio-tile-label">Descripcion</label>
                          </div>
                        </div>

                        <div class="input-container">
                          <input id="Categoria"  v-model="buscar_por" class="radio-button" type="radio" name="radio" value="2" @click="back(2)"/>
                          <div class="radio-tile">
                            <div class="icon bike-icon">
                                  <i class="fa fa-sitemap" aria-hidden="true"></i>
                            </div>
                            <label for="Categoria" class="radio-tile-label">Categoria</label>
                          </div>
                        </div>

                        <div class="input-container">
                          <input id="Codigo"  v-model="buscar_por" class="radio-button" type="radio" name="radio" value="3" @click="back(3)"/>
                          <div class="radio-tile">
                            <div class="icon car-icon">
                                <i class="fa fa-barcode" aria-hidden="true"></i>
                            </div>
                            <label for="Codigo" class="radio-tile-label">Codigo Barra</label>
                          </div>
                        </div>
        </div>
    </div> -->
            <div class="row">
                <div
                    class="col-sm-4"
                    v-for="(item, index) in items"
                    :key="index"
                >
                    <div
                        class="coupon bg-white rounded mb-3 d-flex justify-content-between"
                        id="card"
                    >
                        <div class="kiri p-3">
                            <div class="icon-container ">
                                <div class="icon-container_box">
                                     <img :src="item.image_url" width="60" />
                                </div>
                            </div>
                        </div>
                        <div
                            class="tengah py-3 d-flex w-100 justify-content-start"
                        >
                            <div>
                                <h3 class="lead">{{ item.description }}</h3>
                                <p
                                    class="text-muted mb-0 badge badge-success txt-white"
                                >
                                    {{ item.category }}
                                </p>
                                <p
                                    class="text-muted mb-0 badge badge-info txt-white m-l-0"
                                >
                                    Stock {{ item.stock }}
                                </p>
                                <p
                                    class="text-muted mb-0 badge badge-success txt-white m-l-0"
                                >
                                    Ubicación {{ item.location }}
                                </p>
                            </div>
                        </div>
                        <div class="kanan">
                            <div class="info m-3 d-flex align-items-center">
                                <div class="w-100">
                                    <div class="block">
                                        <span class="time font-weight-light">
                                            <span>
                                                {{ item.currency_type_symbol }}
                                                {{ item.sale_unit_price }}</span
                                            >
                                        </span>
                                    </div>
                                    <a
                                        href="javascript:void(0)"
                                        class="btn btn-sm btn-outline-success"
                                        @click="clickAddItem(item, index)"
                                    >
                                        <i class="fas fa-shopping-cart"></i>
                                        Agregar
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <payment-form
            v-if="is_payment == true"
            :is_payment.sync="is_payment"
            :form="form"
            :direct_printing="direct_printing"
            :currency-type-id-active="form.currency_type_id"
            :currency-type-active="currency_type"
            :exchange-rate-sale="form.exchange_rate_sale"
            :percentage_igv="percentage_igv"
            :customer="customer"
            :soapCompany="soapCompany"
            :businessTurns="businessTurns"
        >
        </payment-form>

        <person-form
            :showDialog.sync="showDialogNewPerson"
            type="customers"
            :input_person="input_person"
            :external="true"
            :document_type_id="form.document_type_id"
        ></person-form>

        <item-form :showDialog.sync="showDialogNewItem" :external="true" >
        </item-form>

        <warehouses-detail
            :showDialog.sync="showWarehousesDetail"
            :warehouses="warehousesDetail"
            :unit_type="unittypeDetail"
        >
        </warehouses-detail>
        <div class="customizer-links">
            <div
                class="nav flex-column nac-pills"
                id="c-pills-tab"
                role="tablist"
                aria-orientation="vertical"
            >
                <a
                    class="nav-link"
                    id="c-pills-home-tab"
                    data-bs-toggle="pill"
                    href="#layout-type"
                    data-bs-original-title=""
                    title=""
                >
                    <div class="settings">
                        <i class="fa fa-shopping-bag"></i>
                    </div>
                    <span>Pedidos Pendientes</span>
                </a>
            </div>
            <div class="customizer-contain">
                <div class="tab-content" id="c-pills-tabContent">
                    <div class="customizer-header">
                        <i class="icofont-close icon-close"></i>
                        <h5>Pedidos Pendientes</h5>
                    </div>
                    <div
                        class="customizer-body custom-scrollbar"
                        style="overflow-x: hidden;"
                    >
                        <div class="tab-pane fade" id="layout-type">
                            <div class="row">
                                <div class="col-md-12">
                                    <div
                                        class="card card-body p-2"
                                        v-for="(item, index) in form.items"
                                        :key="index"
                                    >
                                        <div
                                            class="media align-items-center align-items-lg-start text-center text-lg-left flex-column flex-lg-row"
                                        >
                                            <div class="mr-2 mb-3 mb-lg-0">
                                                <!-- <img
                                                    src="https://i.imgur.com/5Aqgz7o.jpg"
                                                    width="60"
                                                    height="60"
                                                    alt=""
                                                /> -->
                                                 <img :src="item.image_url" width="60" />
                                            </div>

                                            <div class="media-body">
                                                <h6
                                                    class="media-title font-weight-semibold mb-0"
                                                >
                                                    <a
                                                        href="javascript:void(0)"
                                                        data-abc="true"
                                                    >
                                                        {{
                                                            item.item
                                                                .description
                                                        }}
                                                    </a>
                                                </h6>

                                                <ul
                                                    class="list-inline list-inline-dotted mb-3 mb-lg-2"
                                                >
                                                    <li
                                                        class="list-inline-item"
                                                    >
                                                        <a
                                                            href="javascript:void(0)"
                                                            class="text-muted"
                                                            data-abc="true"
                                                        >
                                                            Cantidad<br />
                                                            <el-input-number
                                                                v-model="
                                                                    item.item
                                                                        .aux_quantity
                                                                "
                                                                :precision="4"
                                                                @change="
                                                                    clickAddItem(
                                                                        item,
                                                                        index,
                                                                        true
                                                                    )
                                                                "
                                                                controls-position="right"
                                                                size="mini"
                                                                :min="1"
                                                            >
                                                            </el-input-number>
                                                        </a>
                                                    </li>
                                                    <li
                                                        class="list-inline-item"
                                                    >
                                                        <a
                                                            href="javascript:void(0)"
                                                            class="text-muted"
                                                            data-abc="true"
                                                        >
                                                            Precio<br />
                                                            <el-input-number
                                                                v-model="
                                                                    item.item
                                                                        .sale_unit_price
                                                                "
                                                                :precision="2"
                                                                @change="
                                                                    clickAddItem(
                                                                        item,
                                                                        index,
                                                                        true,
                                                                        item
                                                                            .item
                                                                            .sale_unit_price
                                                                    )
                                                                "
                                                                controls-position="right"
                                                                size="mini"
                                                                :min="1"
                                                            >
                                                            </el-input-number>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div
                                                class="mt-3 mt-lg-0 ml-lg-3 text-center"
                                            >
                                                <h3
                                                    class="mb-0 font-weight-semibold m-b-10"
                                                >
                                                    Importe
                                                    {{ currency_type.symbol }}
                                                    {{ item.total }}
                                                </h3>
                                                <button
                                                    type="button"
                                                    class="btn btn-danger text-white btn-sm"
                                                    @click="
                                                        clickDeleteItem(index)
                                                    "
                                                >
                                                    <i
                                                        class="fas fa-trash fa-wf"
                                                    ></i>
                                                    Quitar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class=" summary">
                            <div class="row">
                                <div
                                    class="col-12 text-right p-t-10 p-r-15"
                                    v-if="form.total_exonerated > 0"
                                >
                                    OP.EXONERADAS : {{ currency_type.symbol }}
                                    {{ form.total_exonerated }}
                                </div>
                                <div
                                    class="col-12 text-right p-t-10 p-r-15"
                                    v-if="form.total_free > 0"
                                >
                                    OP.GRATUITAS : {{ currency_type.symbol }}
                                    {{ form.total_free }}
                                </div>
                                <div
                                    class="col-12 text-right p-t-10 p-r-15"
                                    v-if="form.total_unaffected > 0"
                                >
                                    OP.INAFECTAS : {{ currency_type.symbol }}
                                    {{ form.total_unaffected }}
                                </div>
                                <div
                                    class="col-12 text-right p-t-10 p-r-15"
                                    v-if="form.total_taxed > 0"
                                >
                                    OP.GRAVADA : {{ currency_type.symbol }}
                                    {{ form.total_taxed }}
                                </div>
                                <div
                                    class="col-12 text-right p-b-10 p-r-15"
                                    v-if="form.total_igv > 0"
                                >
                                    IGV : {{ currency_type.symbol }}
                                    {{ form.total_igv }}
                                </div>
                            </div>
                            <button
                                class="btn btn-primary w-100 "
                                @click="clickPayment"
                                v-bind:class="[
                                    form.total > 0
                                        ? 'bg-primary pointer'
                                        : 'bg-dark'
                                ]"
                            >
                                PAGAR {{ currency_type.symbol }}
                                {{ form.total }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<style>
.img-fluid {
    max-width: 50%;
    height: auto;
}
</style>

<script>
import _ from "lodash";
import { calculateRowItem } from "../../../helpers/functions";
import PaymentForm from "./partials/payment.vue";
import ItemForm from "./partials/form.vue";
import { functions, exchangeRate } from "../../../mixins/functions";
import HistorySalesForm from "../../../../../modules/Pos/Resources/assets/js/views/history/sales.vue";
import HistoryPurchasesForm from "../../../../../modules/Pos/Resources/assets/js/views/history/purchases.vue";
import PersonForm from "../persons/form.vue";
import WarehousesDetail from "../items/partials/warehouses.vue";

export default {
    props: ["configuration", "soapCompany", "businessTurns"],
    components: {
        PaymentForm,
        ItemForm,
        PersonForm,
        WarehousesDetail,
    },
    mixins: [functions, exchangeRate],

    data() {
        return {

            slickOptions: {
                slidesToShow: 3
                // Any other options that can be got from plugin documentation
            },
            drawerVisible: false,
            history_item_id: null,
            date_last: null,
            search_item_by_barcode: false,
            warehousesDetail: [],
            unittypeDetail: [],
            input_person: {},
            showDialogHistoryPurchases: false,
            showDialogHistorySales: false,
            showDialogNewPerson: false,
            showDialogNewItem: false,
            loading: false,
            buscar_por: 1,
            userId: null,
            place: false,
            is_payment: false,
            company: null,
            // is_payment: true,//aq
            showWarehousesDetail: false,
            resource: "pos",
            recordId: null,
            input_item: "",
            items: [],
            all_items: [],
            customers: [],
            affectation_igv_types: [],
            all_customers: [],
            establishment: null,
            currency_type: {},
            form_item: {},
            customer: {},
            row: {},
            user: {},
            form: {},
            categories: [],
            document_type_id: null,
            last_date: null,
            direct_printing: 0,
            customer_default: {},
            percentage_igv:null
        };
    },
    async created() {
        this.loading = true;
        await this.getTables();
        await this.nueva_venta();
        this.form.payments = [];
        await this.filterCategorie(false);

        this.loading = false;
            await this.$eventHub.$on("saleSuccess", () => {
                 this.getTables();
                this.initForm();
                this.setFormPosLocalStorage();
            console.log(this.form,"salesucess");
            });
        let form_data={
            establishment_id:this.form.establishment_id,
            date: moment().format('YYYY-MM-DD'),
        }
        const response= await this.$http.post('/get_igv',form_data)
        this.percentage_igv= response.data
    },

    computed: {},
    methods: {
        next() {
            this.$refs.slick.next();
        },

        prev() {
            this.$refs.slick.prev();
        },
        async date_of_issue() {
            let form_date_of_issue = {
                document_type_id: this.form.document_type_id
            };

            const response_date = await this.$http.post(
                `/${this.resource}/date_of_issue`,
                form_date_of_issue
            );

            this.last_date = response_date.data.date_last;
        },
        reInit() {
            // Helpful if you have to deal with v-for to update dynamic lists
            this.$nextTick(() => {
                this.$refs.slick.reSlick();
            });
        },
        async clickPrintPos(printerName, formatoPdf) {
            try {
                let config = qz.configs.create(printerName, {
                    size: {
                        width: 148,
                        height: 210
                    },
                    units: "mm",
                    colorType: "grayscale",
                    copies: 2
                });
                if (!qz.websocket.isActive()) {
                    await qz.websocket.connect(config);
                }
                let data = [
                    {
                        type: "pdf",
                        format: "file",
                        data: formatoPdf
                    }
                ];
                qz.print(config, data).catch(e => {
                    this.$message.error(e.message);
                });
                // this.clickNewSaleNote();
            } catch (e) {
                this.$message.error(e.message);
            }
        },
        clear_input() {
            this.input_item = "";
        },
        selectFocus(input_) {
            this.$refs[input_][0].select();
        },
        teclasInit() {
            document.onkeydown = e => {
                const key = e.key;
                if (key == "F3") {
                    this.$refs.input_item.$el
                        .getElementsByTagName("input")[0]
                        .focus();
                }
                if (key == "F4") {
                    //Agregar cliente
                    this.clickPayment();
                }
                if (key == "F6") {
                    //Agregar Producto
                    //this.clickAddItemInvoice()
                    //return false;
                }
                if (key == "F7" && this.form.items.length > 0) {
                    //Guardar
                    // this.submit()
                }
                if (key == "F8") {
                    //Cancelar
                    // this.close()
                }
            };
        },

        leftarrow(input, index) {
            let split = input.split("_");

            if (split[1] != this.form.items.length) {
                this.$nextTick(this.$refs[index][0].focus());
            }
        },
        rightarrow(index) {
            let split = index.split("_");
            if (split[1] != this.form.items.length) {
                this.$nextTick(this.$refs[index][0].focus());
            }
        },
        upDown(index, next = false) {
            let split = index.split("_");
            if (index != 0) {
                if (split[1] != this.form.items.length) {
                    this.$nextTick(this.$refs[index][0].focus());
                }
                if (split[1] != this.form.items.length) {
                    this.$nextTick(this.$refs[index][0].focus());
                }
            }
        },
        arrowDown(index, next = false) {
            let split = index.split("_");
            if (split[1] != this.form.items.length) {
                this.$nextTick(this.$refs[index][0].focus());
            }
        },
        select_cantidad(index, next = false) {
            let split = index.split("_");
            if (next == false) {
                if (split[1] == this.form.items.length - 1) {
                } else {
                    this.$nextTick(this.$refs[index][0].focus());
                }
            } else {
                if (split[1] != this.form.items.length) {
                    this.$nextTick(this.$refs[index][0].focus());
                }
            }
        },
        clickClose: function() {
            this.$confirm("¿Desea Salir del Punto de Venta?", "Advertencia", {
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cerrar",
                type: "warning"
            }).then(() => {
                location.href = `/dashboard`;
            });
        },
        async nueva_venta() {
            this.initForm();
            //this.events();
            await this.getFormPosLocalStorage();
            await this.initCurrencyType();

            this.customer = await this.getLocalStorageIndex("customer");
        },
        filterCategorie(id, mod = false) {
            if (id) {
                this.items = this.all_items.filter(x => x.category_id == id);
            } else {
                this.filterItems();
            }
        },

        initCurrencyType() {
            this.currency_type = _.find(this.currency_types, {
                id: this.form.currency_type_id
            });
        },

        getFormPosLocalStorage() {
            let form_pos = localStorage.getItem("form_pos");

            form_pos = JSON.parse(form_pos);
            if (form_pos) {
                this.form = form_pos;

                this.calculateTotal();
            }
        },
        deleteFormPosLocalStorage() {
            localStorage.setItem("form_pos", JSON.stringify(this.form));
        },
        setFormPosLocalStorage(form_param = null) {
            /*if (form_param) {
                      localStorage.setItem("form_pos", JSON.stringify(form_param));
                  } else {
                      localStorage.setItem("form_pos", JSON.stringify(this.form));
                  }
                  */
        },
        formatUrlImage(url) {
            if (!url) return;
                let formated ="storage/uploads/items/"+url;
            return `/${formated}`;
            },
        cancelFormPosLocalStorage() {
            localStorage.setItem("form_pos", JSON.stringify(null));
            this.setLocalStorageIndex("customer", null);
        },
        clickOpenInputEditUP(index) {
            this.items[index].edit_unit_price = true;
        },
        clickEditUnitPriceItem(index) {
            // console.log(index)
            let item_search = this.items[index];
            this.items[index].sale_unit_price = this.items[
                index
            ].edit_sale_unit_price;
            this.items[index].edit_unit_price = false;

            // console.log(item_search)
        },
        clickCancelUnitPriceItem(index) {
            // console.log(index)
            this.items[index].edit_unit_price = false;
        },
        clickWarehouseDetail(item) {
            this.unittypeDetail = item.unit_type;
            this.warehousesDetail = item.warehouses;
            this.showWarehousesDetail = true;
        },
        clickHistoryPurchases(item_id) {
            this.history_item_id = item_id;
            this.showDialogHistoryPurchases = true;
            // console.log(item)
        },
        clickHistorySales(item_id) {
            if (!this.form.customer_id)
                return this.$message.error("Debe seleccionar el cliente");
            this.history_item_id = item_id;
            this.showDialogHistorySales = true;
            // console.log(item)
        },
        keyupEnterCustomer() {
            if (this.input_person.number) {
                if (!isNaN(parseInt(this.input_person.number))) {
                    switch (this.input_person.number.length) {
                        case 8:
                            this.input_person.identity_document_type_id = "1";
                            this.showDialogNewPerson = true;
                            break;

                        case 11:
                            this.input_person.identity_document_type_id = "6";
                            this.showDialogNewPerson = true;
                            break;
                        default:
                            this.input_person.identity_document_type_id = "6";
                            this.showDialogNewPerson = true;
                            break;
                    }
                }
            }
        },
        keyupCustomer(e) {
            //if(e.key !== "Enter"){
            this.input_person.number = this.$refs.select_person.$el.getElementsByTagName(
                "input"
            )[0].value;
            let exist_persons = this.all_customers.filter(customer => {
                let pos = customer.description.search(this.input_person.number);
                return pos > -1;
            });
            this.input_person.number =
                exist_persons.length == 0 ? this.input_person.number : null;
            //}
        },
        calculateQuantity(index) {
            // console.log(this.form.items[index])
            if (this.form.items[index].item.calculate_quantity) {
                let quantity = _.round(
                    parseFloat(this.form.items[index].total) /
                        parseFloat(this.form.items[index].unit_price),
                    4
                );

                if (quantity) {
                    this.form.items[index].quantity = quantity;
                    this.form.items[index].item.aux_quantity = quantity;
                } else {
                    this.form.items[index].quantity = 0;
                    this.form.items[index].item.aux_quantity = 0;
                }
                // this.calculateTotal()
            }

            //  this.clickAddItem(this.form.items[index],index, true)
        },

        blurCalculateQuantity(index) {
            this.row = calculateRowItem(
                this.form.items[index],
                this.form.currency_type_id,
                1,
                'documents',
                true,
                this.percentage_igv
            );
            this.form.items[index] = this.row;
            this.calculateTotal();
            this.setFormPosLocalStorage();
        },
        blurCalculateQuantity2(index) {
            this.row = calculateRowItem(
                this.form.items[index],
                this.form.currency_type_id,
                1,
                'documents',
                true,
                this.percentage_igv
            );
            this.form.items[index] = this.row;
            this.calculateTotal();
        },
        changeCustomer() {
            let customer = _.find(this.all_customers, {
                id: this.form.customer_id
            });
            this.customer = customer;
            this.form.document_type_id =
                customer.identity_document_type_id == "1" ? "03" : "01";
            this.setLocalStorageIndex("customer", this.customer);
            //  this.setFormPosLocalStorage()
        },

        getLocalStorageIndex(key, re_default = null) {
            let ls_obj = localStorage.getItem(key);
            ls_obj = JSON.parse(ls_obj);

            if (ls_obj) {
                return ls_obj;
            }

            return re_default;
        },
        setLocalStorageIndex(key, obj) {
            localStorage.setItem(key, JSON.stringify(obj));
        },
        async events() {
            await this.$eventHub.$on("initInputPerson", () => {
                this.initInputPerson();
            });

            await this.$eventHub.$on(
                "eventSetFormPosLocalStorage",
                form_param => {
                    this.setFormPosLocalStorage(form_param);
                }
            );

            await this.$eventHub.$on("cancelSale", () => {
                this.is_payment = false;
                this.getTables();
                this.initForm();
                this.changeExchangeRate();
                //  this.cancelFormPosLocalStorage()
            });

            await this.$eventHub.$on("reloadDataPersons", customer_id => {
                this.reloadDataCustomers(customer_id);
                this.setFormPosLocalStorage();
            });

            await this.$eventHub.$on("reloadDataItems", item_id => {
                this.reloadDataItems(item_id);

                //console.log("Id Item "+item_id)
            });

            await this.$eventHub.$on("saleSuccess", () => {
                //  this.is_payment = false;

                this.getTables();
                this.initForm();
                this.setFormPosLocalStorage();
            });
        },
        initForm() {
            //this.form.customer_id=this.customer_default.id
            //console.log(this.customer_default.id);
            //  console.log(customers_find[0].id)
            //  console.log(customers_find[0].id)
            this.form = {
                afectar_caja: true,
                total_rounded: 0.0,
                total_payment: 0.0,
                establishment_id: null,
                document_type_id: "01",
                series_id: null,
                prefix: null,
                user_id: this.user.id,
                number: "#",
                date_of_issue: moment().format("YYYY-MM-DD"),
                time_of_issue: moment().format("HH:mm:ss"),
                customer_id: this.customer_default.id,
                currency_type_id: "PEN",
                purchase_order: null,
                exchange_rate_sale: 1,
                total_prepayment: 0,
                total_charge: 0,
                total_discount: 0,
                total_exportation: 0,
                total_free: 0,
                total_taxed: 0,
                total_unaffected: 0,
                total_exonerated: 0,
                total_igv: 0,
                total_base_isc: 0,
                total_isc: 0,
                total_base_other_taxes: 0,
                total_other_taxes: 0,
                total_taxes: 0,
                total_value: 0,
                total: 0,
                operation_type_id: "0101",
                date_of_due: moment().format("YYYY-MM-DD"),
                items: [],
                charges: [],
                discounts: [],
                attributes: [],
                guides: [],
                payments: [],
                hotel: {},
                additional_information: null,
                payment_condition_id: "01",
                actions: {
                    format_pdf: "a4"
                },
                difference: 0.0,
                enter_amount: 0.0
            };

            this.initFormItem();
            this.changeDateOfIssue();
            this.initInputPerson();
            this.changeCustomer();
        },
        initInputPerson() {
            this.input_person = {
                number: "",
                identity_document_type_id: ""
            };
        },
        initFormItem() {
            this.form_item = {
                item_id: null,
                item: {},
                affectation_igv_type_id: null,
                affectation_igv_type: {},
                has_isc: false,
                system_isc_type_id: null,
                calculate_quantity: false,
                percentage_isc: 0,
                suggested_price: 0,
                quantity: 1,
                aux_quantity: 1,
                unit_price_value: 0,
                unit_price: 0,
                charges: [],
                discounts: [],
                attributes: [],
                has_igv: false
            };
        },
        async clickPayment() {
            this.form.enter_amount = this.form.total;
            this.form.difference = 0;
            let flag = 0;
            this.form.items.forEach(row => {
                if (row.aux_quantity < 0 || row.total < 0 || isNaN(row.total)) {
                    flag++;
                }
            });

            if (flag > 0)
                return this.$message.error("Cantidad negativa o incorrecta");
            if (!this.form.customer_id)
                return this.$message.error("Seleccione un cliente");
            if (!this.form.items[0])
                return this.$message.error("Seleccione un producto");
            this.form.establishment_id = this.establishment.id;
            this.loading = true;
            const response_date = await this.$http.get(
                `/${this.resource}/print_default`
            );
            this.direct_printing = response_date.data.printer.direct_printing;
            //await this.sleep(800);
            this.is_payment = true;

            this.loading = false;
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        clickDeleteCustomer() {
            this.form.customer_id = null;
            this.setFormPosLocalStorage();
        },
        async clickAddItem(
            item,
            index,
            input = false,
            price_sale = 0,
            focus = false
        ) {
            //this.loading = true;
            let exchangeRateSale = this.form.exchange_rate_sale;
            let exist_item = _.find(this.form.items, { item_id: item.item_id });
            let pos = this.form.items.indexOf(exist_item);
            let response = null;
            if (exist_item) {
                if (input == true) {
                    console.log("1");
                    response = await this.getStatusStock(
                        item.item_id,
                        exist_item.item.aux_quantity
                    );
                    if (!response.success) {
                        item.item.aux_quantity = item.quantity;
                        this.loading = false;
                        return this.$message.error(response.message);
                    }
                    exist_item.quantity = exist_item.item.aux_quantity;
                } else {
                    console.log("2");
                    response = await this.getStatusStock(
                        item.item_id,
                        parseFloat(exist_item.item.aux_quantity) + 1
                    );
                    if (!response.success) {
                        return this.$message.error(response.message);
                    }
                    exist_item.quantity++;
                    exist_item.item.aux_quantity++;
                }

                let search_item_bd = await _.find(this.items, {
                    item_id: item.item_id
                });
                //console.log("precio de venta",search_item_bd)
                if (search_item_bd) {
                    exist_item.item.unit_price = parseFloat(
                        search_item_bd.sale_unit_price
                    );
                }
                if (price_sale > 0) {
                    exist_item.item.sale_unit_price = price_sale;
                }

                let unit_price = exist_item.item.has_igv
                    ? exist_item.item.sale_unit_price
                    : exist_item.item.sale_unit_price * (1+(this.percentage_igv/100));
                // exist_item.unit_price = unit_price
                exist_item.item.unit_price = unit_price;

                this.row = calculateRowItem(
                    exist_item,
                    this.form.currency_type_id,
                    exchangeRateSale,
                    1,
                    'documents',
                    true,
                    this.percentage_igv
                );

                this.form.items[pos] = this.row;
            } else {
                console.log("3");
                response = await this.getStatusStock(item.item_id, 1);
                if (!response.success) {
                    // this.loading = false;
                    return this.$message.error(response.message);
                }

                this.form_item.item = item;
                this.form_item.unit_price_value = this.form_item.item.sale_unit_price;
                this.form_item.has_igv = this.form_item.item.has_igv;
                this.form_item.affectation_igv_type_id = this.form_item.item.sale_affectation_igv_type_id;
                this.form_item.quantity = 1;
                this.form_item.aux_quantity = 1;

                let unit_price = this.form_item.has_igv
                    ? this.form_item.unit_price_value
                    : this.form_item.unit_price_value * (1+(this.percentage_igv/100));

                this.form_item.unit_price = unit_price;
                this.form_item.item.unit_price = unit_price;
                this.form_item.item.presentation = null;

                this.form_item.charges = [];
                this.form_item.discounts = [];
                this.form_item.attributes = [];
                this.form_item.affectation_igv_type = _.find(
                    this.affectation_igv_types,
                    { id: this.form_item.affectation_igv_type_id }
                );

                // console.log(this.form_item)
                this.row = calculateRowItem(
                    this.form_item,
                    this.form.currency_type_id,
                    exchangeRateSale,
                    1,
                    'documents',
                    true,
                    this.percentage_igv
                );
                // console.log(this.row)

                this.form.items.push(this.row);
                item.aux_quantity = 1;
            }

            // console.log("pos", this.row);

            // console.log(this.row)
            // console.log(this.form.items)
            await this.calculateTotal();
            this.loading = false;
            await this.setFormPosLocalStorage();
            if (focus == true) {
                let indexx = this.form.items.length - 1;
                this.$nextTick(this.$refs["input_" + indexx][0].focus());
                this.$nextTick(this.$refs["input_" + indexx][0].select());
            }
            this.$notify({
                title: "",
                message: "Producto añadido!",
                type: "success",
                duration: 700
            });
            localStorage.setItem("form_pos", JSON.stringify(this.form));
        },
        async focus() {
            await this.sleep(200);
            let indexx = this.form.items.length - 1;
        },
        async getStatusStock(item_id, quantity) {
            let data = {};
            if (!quantity) quantity = 0;
            await this.$http
                .get(`/${this.resource}/validate_stock/${item_id}/${quantity}`)
                .then(response => {
                    data = response.data;
                });
            return data;
        },
        async clickDeleteItem(index) {
            this.form.items.splice(index, 1);
            this.calculateTotal();
            this.deleteFormPosLocalStorage();
        },

        calculateTotal() {
            let total_discount = 0;
            let total_charge = 0;
            let total_exportation = 0;
            let total_taxed = 0;
            let total_exonerated = 0;
            let total_unaffected = 0;
            let total_free = 0;
            let total_igv = 0;
            let total_value = 0;
            let total = 0;
            this.form.items.forEach(row => {
                total_discount += parseFloat(row.total_discount);
                total_charge += parseFloat(row.total_charge);

                if (row.affectation_igv_type_id === "10") {
                    total_taxed += parseFloat(row.total_value);
                }
                if (row.affectation_igv_type_id === "20") {
                    total_exonerated += parseFloat(row.total_value);
                }
                if (row.affectation_igv_type_id === "30") {
                    total_unaffected += parseFloat(row.total_value);
                }
                if (row.affectation_igv_type_id === "40") {
                    total_exportation += parseFloat(row.total_value);
                }
                if (
                    ["10", "20", "30", "40"].indexOf(
                        row.affectation_igv_type_id
                    ) < 0
                ) {
                    total_free += parseFloat(row.total_value);
                }
                if (
                    ["10", "20", "30", "40"].indexOf(
                        row.affectation_igv_type_id
                    ) > -1
                ) {
                    total_igv += parseFloat(row.total_igv);
                    total += parseFloat(row.total);
                }
                total_value += parseFloat(row.total_value);
            });

            this.form.total_exportation = _.round(total_exportation, 2);
            this.form.total_exonerated = _.round(total_exonerated, 2);
            this.form.total_taxed = _.round(total_taxed, 2);
            this.form.total_exonerated = _.round(total_exonerated, 2);

            // this.form.total_taxed =
            //   _.round(total_taxed, 2) + this.form.total_exonerated;
            // this.form.total_exonerated = _.round(total_exonerated, 2)
            this.form.total_unaffected = _.round(total_unaffected, 2);
            this.form.total_free = _.round(total_free, 2);
            this.form.total_igv = _.round(total_igv, 2);
            this.form.total_value = _.round(total_value, 2);
            this.form.total_taxes = _.round(total_igv, 2);
            this.form.total = _.round(total, 2);
        },
        changeDateOfIssue() {
            // this.searchExchangeRateByDate(this.form.date_of_issue).then(response => {
            //     this.form.exchange_rate_sale = response
            // })
        },
        changeExchangeRate() {
            this.searchExchangeRateByDate(this.form.date_of_issue).then(
                response => {
                    this.form.exchange_rate_sale = response;
                }
            );
        },
        async getTables() {
            await this.$http.get(`/${this.resource}/tables`).then(response => {
                this.all_items = response.data.items;
                this.categories = response.data.categories;
                this.date_last = response.data.date_last;
                this.affectation_igv_types = response.data.affectation_igv_types;
                this.all_customers = response.data.customers;
                this.establishment = response.data.establishment;
                this.currency_types = response.data.currency_types;
                this.customer_default = response.data.customers_default;
                this.user = response.data.user;
                this.company = response.data.company.name;
                this.currency_types.length > 0
                    ? this.currency_types[0].id
                    : null;
                // this.renderCategories(response.data.categories)
                // this.currency_type = _.find(this.currency_types, {'id': this.form.currency_type_id})
                // this.changeCurrencyType();
                this.filterItems();
                this.changeDateOfIssue();
                this.changeExchangeRate();
                this.document_type_id =
                    response.data.establishment.document_default;
            });
        },
        renderCategories(source) {
            const contex = this;
            this.categories = source.map((obj, index) => {
                return {
                    id: obj.id,
                    name: obj.name,
                    color: contex.getColor(index)
                };
            });

            this.categories.unshift({
                id: null,
                name: "Todos",
                color: "#2C8DE3"
            });
        },
        searchItems() {
            if (this.input_item.length > 0) {
                this.loading = true;
                let parameters = `input_item=${this.input_item}`;

                this.$http
                    .get(`/${this.resource}/search_items?${parameters}`)
                    .then(response => {
                        console.log(response);
                        this.items = response.data.items;

                        this.loading = false;
                        if (this.items.length == 0) {
                            this.filterItems();
                        }
                    });
            } else {
                // this.customers = []
                this.filterItems();
            }
        },
        async searchItemsBarcode() {
            // console.log(query)
            // console.log("in:" + this.input_item)

            if (this.input_item.length > 1) {
                this.loading = true;
                let parameters = `input_item=${this.input_item}`;

                await this.$http
                    .get(`/${this.resource}/search_items?${parameters}`)
                    .then(response => {
                        this.items = response.data.items;
                        this.enabledSearchItemsBarcode();
                        this.loading = false;
                        if (this.items.length == 0) {
                            this.filterItems();
                        }
                    });
            } else {
                await this.filterItems();
            }
        },
        enabledSearchItemsBarcode() {
            if (this.search_item_by_barcode) {
                if (this.items.length == 1) {
                    // console.log(this.items)
                    this.clickAddItem(this.items[0], 0);
                    this.filterItems();
                }

                this.cleanInput();
            }
        },
        changeSearchItemBarcode() {
            this.cleanInput();
        },
        cleanInput() {
            this.input_item = null;
        },
        filterItems() {
            this.items = this.all_items;
        },
        reloadDataCustomers(customer_id) {
            this.$http
                .get(`/${this.resource}/table/customers`)
                .then(response => {
                    this.all_customers = response.data;
                    this.form.customer_id = customer_id;
                    this.changeCustomer();
                });
        },
        reloadDataItems(item_id) {
            this.$http.get(`/${this.resource}/table/items`).then(response => {
                this.all_items = response.data;
                let array_temp = _.filter(this.all_items, {
                    id: item_id
                });
                this.input_item = array_temp[0].description;
                this.filterItems();
                this.searchItems();
                this.clickAddItem(array_temp[0], 0, false);
            });
        },
        selectCurrencyType() {
            this.form.currency_type_id =
                this.form.currency_type_id === "PEN" ? "USD" : "PEN";
            this.changeCurrencyType();
        },
        async changeCurrencyType() {
            // console.log(this.form.currency_type_id)
            this.currency_type = await _.find(this.currency_types, {
                id: this.form.currency_type_id
            });
            let items = [];
            this.form.items.forEach(row => {
                items.push(
                    calculateRowItem(
                        row,
                        this.form.currency_type_id,
                        this.form.exchange_rate_sale,
                        1,
                        'documents',true,this.percentage_igv
                    )
                );
            });
            this.form.items = items;
            this.calculateTotal();

            await this.setFormPosLocalStorage();
        },
        openFullWindow() {
            location.href = `/${this.resource}/pos_full`;
        },
        back(valor) {
            if (valor == 2) {
                this.place = true;
            } else {
                this.place = false;
                this.$refs.input_item.$el
                    .getElementsByTagName("input")[0]
                    .focus();
            }
        },
        setView() {
            this.place = "cat2";
        },
        nameSets(id) {
            let row = this.items.find(x => x.item_id == id);
            if (row) {
                if (row.sets.length > 0) {
                    return row.sets.join("-");
                } else {
                    return "";
                }
            }
        }
    },
    mounted() {
        this.teclasInit();
    }
};
</script>
