<template>
    <el-dialog :title="titleDialog" :visible="showDialog" @open="create" width="30%"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :show-close="false">

        <div class="row">             
            
            <template v-if="form.upload_filename">

                <div class="col-lg-6 col-md-6 col-sm-6 text-center font-weight-bold mt-4">
                    <p>Imprimir A4</p>
                    <button type="button" class="btn btn-lg btn-info waves-effect waves-light" @click="clickPrint('a4')">
                        <i class="fa fa-file-alt"></i>
                    </button>
                </div> 
                <div class="col-lg-6 col-md-6 col-sm-6 text-center font-weight-bold mt-4">
                    <p>Descargar Archivo</p>
                    <button type="button" class="btn btn-lg btn-info waves-effect waves-light" @click="clickDownload()">
                        <i class="fa fa-download"></i>
                    </button>
                </div> 
            </template>
            <template v-else>

                <div class="col-lg-12 col-md-12 col-sm-12 text-center font-weight-bold mt-4">
                    <p>Imprimir A4</p>
                    <button type="button" class="btn btn-lg btn-info waves-effect waves-light" @click="clickPrint('a4')">
                        <i class="fa fa-file-alt"></i>
                    </button>
                </div> 
            </template>
        </div>  
        <div class="row mt-3">
            <div class="col-md-12">
                <el-input v-model="form.customer_telephone">
                    <template slot="prepend">+51</template>
                        <el-button slot="append" @click="clickSendWhatsapp"  :loading="loading_Whatsapp">Enviar
                            <el-tooltip class="item" effect="dark"  placement="top-start">
                                <i class="fab fa-whatsapp" ></i>
                            </el-tooltip>
                        </el-button>
                    </el-input>
                <small class="form-control-feedback" v-if="errors.customer_telephone" v-text="errors.customer_telephone[0]"></small>
            </div>
        </div> 
        <span slot="footer" class="dialog-footer">
            <template v-if="showClose">
                <el-button @click="clickClose">Cerrar</el-button>
            </template>
            <template v-else>
                <el-button @click="clickFinalize">Ir al listado</el-button>
                <el-button type="primary" @click="clickNewDocument">{{button_text}}</el-button>
            </template>
        </span>
    </el-dialog>
</template>

<script>

    export default {
        props: ['showDialog', 'recordId', 'showClose', 'type','isUpdate','company','configuration'],
        data() {
            return {
                titleDialog: null,
                loading: false,
                resource: 'purchase-orders',
                button_text:'Nueva OC',
                errors: {},
                form: {},
                loading_Whatsapp: false,
            }
        },
        created() {
            this.initForm()
        },
        methods: {
            clickPrint(format){
                window.open(`/${this.resource}/print/${this.form.external_id}/${format}`, '_blank');
            },
            clickDownload(){
                window.open(`/${this.resource}/download-attached/${this.form.external_id}`, '_blank');
            },
            initForm() {
                this.errors = {}
                this.form = {
                    id: null,
                    external_id: null,
                    number: null,
                    customer_email: null,
                    upload_filename: null,
                    message_text:null,
                    download_pdf: null
                }
            },
            create() {
                this.$http.get(`/${this.resource}/record/${this.recordId}`)
                    .then(response => {
                        this.form = response.data.data
                        let typei = this.type == 'edit' ? 'editada' : 'registrada'
                        this.titleDialog = `Orden de Compra ${typei}: ` + this.form.number_full
                    })
                this.button_text = this.isUpdate ? 'Continuar':'Nueva OC'
            },

            clickFinalize() {
                location.href = `/${this.resource}`
            },
            clickNewDocument() {
                this.clickClose()
            },
            clickSendWhatsapp() {
            if (!this.form.customer_telephone) {
                return this.$message.error('El número es obligatorio')
            }
            if (this.configuration.api_whatsapp == 0 || this.configurationapi_whatsapp == false) {
                window.open(`https://wa.me/51${this.form.customer_telephone}?text=${this.form.message_text}`, '_blank');
            } else {
                if (!this.form.customer_telephone) {
                    return this.$message.error('El número es obligatorio')
                }
                this.loading_Whatsapp = true
                let form = {
                    id: this.recordId,
                    customer_telephone: this.form.customer_telephone,
                    mensaje: 'la Orden de Compra Nº ' + this.form.number_full + ' de la empresa *' + this.company.name + "* con Nº RUC  *" + this.company.number + '*  ha sido generado correctamente atra vez del sistema de facturacion electronica de la empresa  *GRUPOPCSYSTEMS*',
                    type_id: "OC"
                }
                this.$http.post(`/whatsapp`, form)
                    .then(response => {
                        if (response.data.success == true) {
                            this.$message.success(response.data.message)
                            this.loading_Whatsapp = false
                        }
                    })
                //  window.open(`https://wa.me/51${this.form.customer_telephone}?text=${this.form.message_text}`, '_blank');
            }
        },
            clickClose() {
                this.$emit('update:showDialog', false)
                this.initForm()
            },
        }
    }
</script>
